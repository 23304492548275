import React from 'react';
import { EuiInMemoryTable } from '@elastic/eui';
import { apiCall, convertEpochToDate } from '../../utils';
import { renderCreatorIdentityServicePrinciple } from '../../utils/JsxUtils';

const columns = [
  {
    field: 'componentName',
    name: 'Component Version',
  },
  {
    field: 'createdBy',
    name: 'Created By',
    render: (item) => {
      if (typeof item === 'function') return item();
      return item;
    },
  },
  {
    field: 'creationTime',
    name: 'Creation Time',
  },
  {
    field: 'lastModificationTime',
    name: 'Last Modification Time',
  },
  {
    field: 'lastModifiedBy',
    name: 'Last Modified By',
  },
];

const pagination = {
  initialPageSize: 10,
  pageSizeOptions: [10, 20, 40, 100],
};

export class ComponentVersionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestActive: true,
      exception: null,
      items: [],
    };
  }

  componentDidMount() {
    this.getComponentVersions();
  }

  updateResult(response) {
    const { items } = this.state;
    response.body.componentVersions.forEach((value) => {
      const {
        componentVersion: { majorVersion, minorVersion, componentName },
        auditProperties: {
          createdBy,
          creationTime,
          lastModifiedBy,
          lastModificationTime,
        },
      } = value;
      items.push({
        componentName: `${componentName}-${majorVersion}-${minorVersion}`,
        createdBy: renderCreatorIdentityServicePrinciple(createdBy),
        creationTime: convertEpochToDate(creationTime),
        lastModifiedBy,
        lastModificationTime: convertEpochToDate(lastModificationTime),
      });
    });
    this.setState({ items });
  }

  updateException(response) {
    this.setState({ exception: JSON.stringify(response) });
  }

  updateResponse(response) {
    this.setState({ statusCode: response.statusCode });
    if (response.statusCode === 200) {
      this.updateResult(response);
    } else {
      this.updateException(response);
    }
  }

  getComponentVersions = async (nextToken) => {
    const data = {
      stackName: this.props.stackName,
      componentName: this.props.componentName,
      componentScopeDefinition: {
        componentScope: this.props.componentScope,
      },
      maxResults: 100,
      isDeprecated: false,
      nextToken,
    };
    const response = await apiCall('/component/version/list', data);
    this.updateResponse(response);

    if (response.body.nextToken) {
      await this.getComponentVersions(response.body.nextToken);
    } else {
      this.setState({ isRequestActive: false });
    }
  };

  render() {
    const { items, isRequestActive, exception } = this.state;
    return (
      <EuiInMemoryTable
        items={items}
        columns={columns}
        pagination={pagination}
        loading={isRequestActive}
        error={exception}
        itemId="componentName"
      />
    );
  }
}
