import React from 'react';
import { EuiInMemoryTable, EuiLoadingSpinner } from '@elastic/eui';
import { OperationTableColumnDefinition } from '../Operation/OperationTableColumnDefinition';
import { apiCall, convertEpochToDate } from '../../../utils';
import { pagination } from '../constants';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';

export class OperationList extends React.Component {
  static notApplicable = 'N/A';

  constructor(props) {
    super(props);
    this.state = {
      operationDetailsList: [],
      isLoading: false,
      isError: false
    };
  }

  componentDidMount() {
    this.refreshListOperations();
  }

  refreshListOperations = async () => {
    this.setState({ isLoading: true, isError: false });

    try {
      const response = await apiCall('/nodeOperation/list', {
        stackName: this.props.stackName,
        clusterId: this.props.clusterId,
      });

      if (response.statusCode !== 200 || !response.body?.operationDetailsList) {
        this.setState({ isLoading: false, isError: true });
        return [];
      }

      const processedOperations = this.processOperations(response.body.operationDetailsList);

      this.setState({
        operationDetailsList: processedOperations,
        isLoading: false,
        isError: false
      }, () => {
        if (this.props.onOperationsUpdated) {
          this.props.onOperationsUpdated(processedOperations);
        }
      });

      return processedOperations;
    } catch (error) {
      this.setState({
        isLoading: false,
        isError: true,
        operationDetailsList: []
      });
    }
    return [];
  };

  processOperations = (nodeOperations = []) => {
    const processed = nodeOperations.map(nodeOperation => ({
      ...nodeOperation,
      startTime: nodeOperation.startTime ? convertEpochToDate(nodeOperation.startTime) : OperationList.notApplicable,
      endTime: nodeOperation.endTime ? convertEpochToDate(nodeOperation.endTime) : OperationList.notApplicable,
      errorMessage: nodeOperation.errorMessage || OperationList.notApplicable,
      taskID: nodeOperation.taskID || OperationList.notApplicable,
      rawStartTime: Number(nodeOperation.startTime) || 0
    }));
    return [...processed].sort((a, b) => b.rawStartTime - a.rawStartTime);
  };

  renderTable() {
    return (
      <EuiInMemoryTable
        pagination={pagination}
        columns={OperationTableColumnDefinition}
        items={this.state.operationDetailsList}
        sorting={{
          sort: {
            field: 'rawStartTime',
            direction: 'desc',
          },
        }}
        search={{
          box: {
            incremental: true,
            placeholder: 'Search nodeOperations...',
          },
        }}
      />
    );
  }

  render() {
    const { isLoading, isError } = this.state;

    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <EuiLoadingSpinner size="xl" />
        </div>
      );
    }

    if (isError) {
      return <ErrorDisplay />;
    }

    return (
      <ElementErrorBoundary>
        {this.renderTable()}
      </ElementErrorBoundary>
    );
  }
}
