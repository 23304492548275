// NodeOperation/constants.js

// Pagination settings
export const pagination = {
  initialPageSize: 5,
  pageSizeOptions: [5, 10, 20, 100],
};

// Operation Status colors
export const STATUS_COLORS = {
  SUCCESS: 'success',
  FAILED: 'danger',
  IN_PROGRESS: 'primary',
  PENDING: 'warning',
  COMPLETED: 'success',
  SKIPPED_CLUSTER_NOT_FOUND: 'warning',
  DEFAULT: 'default'
};

// API Endpoints
export const API_ENDPOINTS = {
  LIST_OPERATIONS: '/nodeOperation/list',
  ACTION_HISTORY: '/nodeOperation/actionExecutionHistory'
};

// Tab names
export const TABS = {
  OPERATIONS: 'nodeOperations',
  HISTORY: 'history'
};

// Table settings
export const TABLE_SETTINGS = {
  SORT_FIELD: 'startTime',
  SORT_DIRECTION: 'desc',
  SEARCH_PLACEHOLDER: 'Search nodeOperations...'
};

// Loading states
export const LOADING_STATES = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success'
};

// Error messages
export const ERROR_MESSAGES = {
  LOAD_OPERATIONS: 'Failed to load nodeOperations data',
  LOAD_HISTORY: 'Failed to load deployment action history',
  NO_ACTIONS: 'No deployment action history is available for this Task ID'
};

// Default values
export const DEFAULTS = {
  NOT_AVAILABLE: 'N/A',
  PAGE_SIZE: 10,
  MAX_ITEMS: 100
};

// Action Step Types
export const STEP_TYPES = {
  PRE: 'PRE',
  POST: 'POST',
  DEPLOY: 'DEPLOY'
};
