import React from 'react';
import PropTypes from 'prop-types';
import BarChart from "@amzn/awsui-components-react/polaris/bar-chart";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { Button } from '@amzn/awsui-components-react/polaris';
import { DEPLOYMENT_COLORS } from '../../constants';

export const StageBarChart = ({
                                stageData,
                                selectedFilters,
                                onFilterChange,
                                onStageSelect
                              }) => {
  const getBarChartData = () => {
    if (!stageData?.stages) return [];

    return stageData.stages.map(stage => {
      const inProgress = stage.deployMetrics.nScheduled -
        (stage.deployMetrics.nSuccess + stage.deployMetrics.nFailed);
      return {
        stage: `Stage ${stage.stageId}`,
        success: stage.deployMetrics.nSuccess,
        failed: stage.deployMetrics.nFailed,
        inProgress: inProgress
      };
    });
  };

  const getFilteredSeries = () => {
    const barChartData = getBarChartData();

    const allSeries = [
      {
        title: "Success",
        type: "bar",
        data: barChartData.map(item => ({
          x: item.stage,
          y: item.success
        })),
        color: DEPLOYMENT_COLORS.SUCCESS
      },
      {
        title: "Failed",
        type: "bar",
        data: barChartData.map(item => ({
          x: item.stage,
          y: item.failed
        })),
        color: DEPLOYMENT_COLORS.FAILED
      },
      {
        title: "In Progress",
        type: "bar",
        data: barChartData.map(item => ({
          x: item.stage,
          y: item.inProgress
        })),
        color: DEPLOYMENT_COLORS.IN_PROGRESS
      }
    ];

    return allSeries.filter(series => selectedFilters.includes(series.title));
  };

  const barChartData = getBarChartData();

  return (
    <Container
      header={
        <Header
          variant="h3"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <select
                value={selectedFilters.length === 3 ? 'all' : selectedFilters.join(',')}
                onChange={onFilterChange}
                style={{
                  padding: '4px 8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              >
                <option value="all">All</option>
                <option value="Success">Success</option>
                <option value="Failed">Failed</option>
                <option value="In Progress">In Progress</option>
                <option value="Success,Failed">Success & Failed</option>
                <option value="Success,In Progress">Success & In Progress</option>
                <option value="Failed,In Progress">Failed & In Progress</option>
              </select>
            </SpaceBetween>
          }
        >
          Stage-wise Progress
        </Header>
      }
    >
      <BarChart
        series={getFilteredSeries()}
        xDomain={barChartData.map(item => item.stage)}
        yDomain={[0, Math.max(...barChartData.map(item =>
          Math.max(
            selectedFilters.includes('Success') ? item.success : 0,
            selectedFilters.includes('Failed') ? item.failed : 0,
            selectedFilters.includes('In Progress') ? item.inProgress : 0
          )
        ))]}
        stackedBars
        horizontalBars
        hideFilter={true}
        xTitle="Stages"
        yTitle="Number of Clusters"
        ariaLabel="Stage-wise deployment status"
        detailPopoverContent={(x, y, series) => [
          { key: "Stage", value: x },
          { key: series.title, value: y },
        ]}
        detailPopoverFooter={(x) => (
          <Button
            onClick={() => onStageSelect(x)}
            ariaLabel={`View details for ${x}`}
          >
            View details
          </Button>
        )}
      />
    </Container>
  );
};

StageBarChart.propTypes = {
  stageData: PropTypes.shape({
    stages: PropTypes.arrayOf(PropTypes.object)
  }),
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onStageSelect: PropTypes.func.isRequired
};
