import { AppLayout, BreadcrumbGroup, Container, Header, Button, SpaceBetween, FormField, Input, Box } from '@amzn/awsui-components-react';
import React, { useState, useEffect } from 'react';
import { AppContext, getInitialAppContext } from '../../utils/appContext';
import { Navbar } from '../../utils/Navbar';
import { Sidebar } from '../../utils/sidebar';
import { tumblerNavbarOptions } from '../../tumblerConstants';
import { apiCall, getDefaultStack } from '../../sdpds/utils';
import { ComponentReleaseTrackerDetail } from '../../sdpds/application/ComponentReleaseTracker/Details/ComponentReleaseTrackerDetail';

const ComponentReleaseTracker = () => {
  const appContext = getInitialAppContext();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [stackName, setStackName] = useState('');
  const [displayedStackName, setDisplayedStackName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showComponent, setShowComponent] = useState(true);

  const setTimezone = (timezone) => {
    setState({ ...state, timezone: timezone });
  };

  const setBreadcrumbs = (breadcrumbs) => {
    setState({ ...state, breadcrumbs });
  };

  const [state, setState] = useState({
    ...getInitialAppContext(),
    setTimezone: setTimezone,
    setBreadcrumbs: setBreadcrumbs,
  });

  const fetchDefaultStack = async () => {
    setIsLoading(true);
    try {
      const config = await apiCall('/getConfig', {});
      if (config.statusCode === 200) {
        const defaultStack = getDefaultStack(config.body);
        setStackName(defaultStack);
        setDisplayedStackName(defaultStack);
      }
    } catch (err) {
      setError('Failed to fetch default stack');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDefaultStack();
  }, []);

  const handleViewTracker = () => {
    setShowComponent(false);
    setTimeout(() => {
      setDisplayedStackName(stackName);
      setShowComponent(true);
    }, 0);
  };

  const handleKeyDown = ({ detail }) => {
    if (detail.key === 'Enter' && stackName && !isLoading) {
      handleViewTracker();
    }
  };


  return (
    <div>
      <Navbar
        title={'Component Release Tracker'}
        homeUrl="/component-release-tracker"
        regionSwitcherActive={true}
        dropdownOptions={tumblerNavbarOptions}
      />
      <div style={{ paddingTop: '60px' }}>
        <AppLayout
          disableContentPaddings={false}
          content={
            <AppContext.Provider value={state}>
              <SpaceBetween direction="vertical" size="l">
                <Container
                  header={
                    <Header variant="h2">Component Release Tracker</Header>
                  }
                >
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ width: '400px' }}>
                      <FormField label={<strong>Stack Name</strong>}>
                        <Input
                          placeholder="Enter stack name"
                          value={stackName}
                          onChange={({ detail }) => setStackName(detail.value)}
                          onKeyDown={handleKeyDown}
                          disabled={isLoading}
                        />
                      </FormField>
                    </div>
                    <FormField label="-">
                      <Button
                        variant="primary"
                        onClick={handleViewTracker}
                        disabled={!stackName || isLoading}
                      >
                        View Tracker
                      </Button>
                    </FormField>
                  </SpaceBetween>
                  {error && (
                    <Box padding={{ top: 's' }}>
                      {error}
                    </Box>
                  )}
                </Container>
                {displayedStackName && showComponent && (
                  <ComponentReleaseTrackerDetail
                    stackName={displayedStackName}
                  />
                )}
              </SpaceBetween>
            </AppContext.Provider>
          }
          toolsHide={true}
          navigation={<Sidebar />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          headerSelector="#navbar"
          breadcrumbs={
            <BreadcrumbGroup
              items={appContext.breadcrumbs}
              ariaLabel="Breadcrumbs"
            />
          }
        />
      </div>
    </div>
  );
};

export { ComponentReleaseTracker };
