export const DEPLOYMENT_COLORS = {
  // Success states
  SUCCESS: '#1D8102',          // Green
  PARTIAL_SUCCESS: '#FF9900',  // Orange

  // Failed states (variations of red)
  FAILED: '#D13212',          // Base red
  FAILED_HEALTH_CHECK: '#BA0517',
  FAILED_TASK_ABORTED: '#C72B1C',
  FAILED_ROLLBACK_DONE: '#B22222',
  FAILED_VALIDATION: '#CC0000',

  // In-progress/Scheduled states
  IN_PROGRESS: '#0972D3',      // Blue
  SCHEDULED: '#879596',       // Grey

  // Error/Validation states
  ERROR: '#FD6500',           // Orange-red
  VALIDATE_FAILED: '#D13212', // Red

  // Skipped states (variations of yellow/amber)
  SKIPPED: '#8D6E00',
  SKIPPED_RELEASE_STAGE_CANCELLED: '#997A00',
  SKIPPED_UPDATE_IN_PROGRESS: '#8D6E00',
  SKIPPED_CLUSTER_NOT_FOUND: '#997A00',
  SKIPPED_UPDATE_ALREADY_EXISTS: '#8D6E00'
};

export const STATUS_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  PENDING: 'pending',
  INFO: 'info'
};

export const RELEASE_STATUS_COLOR = {
  SCHEDULED: 'primary',
  IN_PROGRESS: 'warning',
  COMPLETED: 'success',
  FAILED: 'danger',
  TERMINATED: 'danger',
  ABORTED: 'danger',
};

export const CLUSTER_INITIAL_STATE = {
  items: [],
  isLoading: true,
  error: null,
  nextToken: null,
  currentPageIndex: 1,
  pageSize: 20,
  totalItemCount: 0,
  hasMoreItems: false,
  filteringText: ''
};

export const CLUSTER_PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 clusters" },
  { value: 10, label: "10 clusters" },
  { value: 20, label: "20 clusters" },
  { value: 50, label: "50 clusters" }
];
