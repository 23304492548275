import React from 'react';
import PieChart from "@amzn/awsui-components-react/polaris/pie-chart";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { DEPLOYMENT_COLORS } from '../../constants';

export const StatusPieChart = ({ stageData }) => {
  const getPieChartData = () => {
    const totals = calculateTotals();
    return [
      {
        title: "Success",
        value: totals.success,
        color: DEPLOYMENT_COLORS.SUCCESS,
        lastUpdate: new Date().toLocaleDateString()
      },
      {
        title: "Failed",
        value: totals.failed,
        color: DEPLOYMENT_COLORS.FAILED,
        lastUpdate: new Date().toLocaleDateString()
      },
      {
        title: "In Progress",
        value: totals.inProgress,
        color: DEPLOYMENT_COLORS.IN_PROGRESS,
        lastUpdate: new Date().toLocaleDateString()
      }
    ];
  };

  const calculateTotals = () => {
    if (!stageData?.stages) return { scheduled: 0, success: 0, failed: 0, inProgress: 0 };

    return stageData.stages.reduce(
      (acc, stage) => {
        const inProgress = stage.deployMetrics.nScheduled -
          (stage.deployMetrics.nSuccess + stage.deployMetrics.nFailed);
        return {
          scheduled: acc.scheduled + stage.deployMetrics.nScheduled,
          success: acc.success + stage.deployMetrics.nSuccess,
          failed: acc.failed + stage.deployMetrics.nFailed,
          inProgress: acc.inProgress + inProgress
        };
      },
      { scheduled: 0, success: 0, failed: 0, inProgress: 0 }
    );
  };

  return (
    <Container header={<Header variant="h3">Overall Status</Header>}>
      <PieChart
        data={getPieChartData()}
        detailPopoverContent={(datum, sum) => [
          { key: "Clusters", value: datum.value },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(1)}%`
          },
          { key: "Last Update", value: datum.lastUpdate }
        ]}
        segmentDescription={(datum, sum) =>
          `${datum.value} clusters (${((datum.value / sum) * 100).toFixed(1)}%)`
        }
        ariaDescription="Pie chart showing overall deployment status"
        size="medium"
        hideFilter
      />
    </Container>
  );
};
