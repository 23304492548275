export const JUNO_API_OPTIONS = [
    { value: '_cat', label: '_cat' },
    { value: '_mappings', label: '_mappings' },
    { value: '_alias', label: '_alias' },
    { value: '_component_template', label: '_component_template' },
    { value: '_index_template', label: '_index_template' },
    { value: 'index', label: 'index' },
  ];
  
  export const JUNO_APIS = {
    cat: [
      { value: 'indices', label: 'indices' },
      { value: 'aliases', label: 'aliases' },
      { value: 'templates', label: 'templates' },
    ],
    mappings: [],
    alias: [],
    component_template: [],
    index_template: [],
    index: [
      { value: '_mappings', label: '_mappings' },
      { value: '_settings', label: '_settings' },
    ],
  };
  
  export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Resources' },
    { value: 20, label: '20 Resources' },
    { value: 50, label: '50 Resources' },
    { value: 100, label: '100 Resources' },
  ];
  
  export const CAT_API_COLUMNS = {
    indices: ['status', 'index', 'uuid', 'docs.count', 'docs.deleted', 'store.size', 'pri.store.size'],
    aliases: ['alias', 'index', 'filter', 'routing.index', 'routing.search', 'is_write_index'],
    templates: ['name', 'index_patterns', 'order', 'version', 'composed_of']
  };
  
  export const CODE_VIEWER_APIS = ['_mappings', '_alias', '_component_template', '_index_template', 'index'];