import React from 'react';
import {
  EuiHealth,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiButton,
  EuiSpacer,
  EuiAccordion,
  EuiCodeBlock,
  EuiLoadingSpinner,
  EuiText,
  EuiCallOut
} from '@elastic/eui';
import { ErrorDisplay } from '../../../components/ErrorDisplay';

const renderActionAccordion = (action, index) => {
  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case 'SUCCESS':
        return 'success';
      case 'FAILED':
        return 'danger';
      default:
        return 'warning';
    }
  };

  const buttonContent = (
    <EuiFlexGroup gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false}>
        <EuiText size="s">
          {`${action.actionName} - ${action.stepType}`}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiHealth color={getStatusColor(action.status)}>
          {action.status}
        </EuiHealth>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <EuiAccordion
      key={index}
      id={`action-${index}`}
      buttonContent={buttonContent}
      paddingSize="m"
    >
      <EuiCodeBlock language="json" paddingSize="m" isCopyable>
        {JSON.stringify(action, null, 2)}
      </EuiCodeBlock>
    </EuiAccordion>
  );
};

export const renderActionHistory = ({
                                      isLoading,
                                      isError,
                                      selectedTaskId,
                                      deploymentActionResult,
                                      handleTaskIdChange,
                                      refreshDeploymentActionHistory
                                    }) => {
  return (
    <>
      <EuiFlexGroup gutterSize="m" alignItems="center">
        <EuiFlexItem>
          <EuiFieldText
            placeholder="Enter Task ID"
            value={selectedTaskId}
            onChange={handleTaskIdChange}
            fullWidth
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => refreshDeploymentActionHistory(selectedTaskId)}
            isLoading={isLoading}
          >
            Search
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {isLoading ? (
        <EuiLoadingSpinner size="xl" />
      ) : isError ? (
        <ErrorDisplay />
      ) : deploymentActionResult ? (
        deploymentActionResult.actionStepResults?.length > 0 ? (
          <EuiFlexGroup direction="column" gutterSize="s">
            {deploymentActionResult.actionStepResults.map((action, index) => (
              <EuiFlexItem key={index}>
                {renderActionAccordion(action, index)}
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        ) : (
          <EuiCallOut
            title="No deployment actions found"
            color="danger"
            iconType="alert"
          >
            <p>No deployment action history is available for this Task ID.</p>
          </EuiCallOut>
        )
      ) : null}
    </>
  );
};
