export interface DetailsTab {
  id: string;
  label: string;
  route: string;
}
export const tabs = [
  {
    id: 'overview',
    label: 'Overview',
    route: 'overview',
  },
  {
    id: 'apiExplored',
    label: 'API explorer',
    route: 'explorer',
  },
  {
    id: 'configurations',
    label: 'Configurations',
    route: 'configurations',
  },
  {
    id: 'snapshots',
    label: 'Snapshots',
    route: 'snapshots',
  },
  {
    id: 'skewAnalysis',
    label: 'Skew analysis',
    route: 'skew-analysis',
  },
  {
    id: 'upgradeHistory',
    label: 'Upgrade history',
    route: 'upgrade-history',
  },
  {
    id: 'sdpds',
    label: 'SDPDS',
    route: 'sdpds',
  },
];

export const getSelectedTab = (pathname: string) => {
  const selectedTab = tabs.find((tab) => pathname.includes(tab.route));
  return selectedTab
    ? selectedTab
    : tabs.find((tab) => tab.id === 'overview') || tabs[0];
};

  export interface AlertInfo {
    header: string;
    content: string | JSX.Element;
    type: 'success' | 'warning' | 'info' | 'error';
    statusIconAriaLabel: string;
    dismissible?: boolean;
  }

  export const ALERTS: AlertInfo[] = [
    {
      header: "Note",
      content: "Few OpsTumbler commands can have access to customer data, please exercise caution while using commands that has access to customer data.",
      type: "warning",
      statusIconAriaLabel: "Warning",
    },
    {
      header: "New Feature",
      content: (
          <>
            Get your queries addressed faster! Try our new AI Assistant Chatbots.
            <br />
            <a href="https://console.harmony.a2z.com/internal-ai-assistant?persona=cfd15199-defe-40f5-88db-f86907f8b33b" target="_blank" rel="noopener noreferrer">Tumbler Chatbot</a>
            {' | '}
            <a href="https://console.harmony.a2z.com/internal-ai-assistant?persona=e6f52cf1-f83d-4465-a826-aef16ce70ab3" target="_blank" rel="noopener noreferrer">AES CloudOps Chatbot</a>
          </>
      ),
      type: "info",
      statusIconAriaLabel: "Info",
    },
  ];
