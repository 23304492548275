import React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { STATUS_COLOR } from './constants';
import { convertEpochToDate } from '../../../utils';

export const getReleaseColumns = () => [
  {
    id: 'releaseVersion',
    header: 'Release Version',
    cell: item => item.releaseVersion,
    sortingField: 'releaseVersion',
    width: 200
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => (
      <StatusIndicator
        type={STATUS_COLOR[item.status] || 'info'}
      >
        {item.status}
      </StatusIndicator>
    ),
    sortingField: 'status',
    width: 150
  },
  {
    id: 'componentSetVersion',
    header: 'Component Set Version',
    cell: item => {
      const csv = item.componentSetVersion;
      if (!csv) return 'N/A';
      return `${csv.componentSetName} - ${csv.majorVersion}.${csv.minorVersion}`;
    },
    width: 250
  },
  {
    id: 'deploymentId',
    header: 'Deployment ID',
    cell: item => item.deploymentId,
    sortingField: 'deploymentId',
    width: 250
  },
  {
    id: 'releaseStartTime',
    header: 'Release Start Time',
    cell: item => item.releaseStartTime ? convertEpochToDate(item.releaseStartTime) : 'N/A',
    sortingField: 'releaseStartTime',
    width: 200
  }
];
