import React from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiToolTip
} from '@elastic/eui';
import ElementErrorBoundary from '../../../../components/ErrorBoundary/ElementErrorBoundary';

export const DeploymentTypeOptions = ({ deploymentTypeData = {} }) => {
  const deploymentTypes = Object.keys(deploymentTypeData);

  return (
    <ElementErrorBoundary>
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Available deployment type configurations">
                <strong>Deployment Type Options</strong>
              </EuiToolTip>
            </h4>
          </EuiText>
        </EuiFlexItem>

        <div style={{ marginLeft: '24px' }}>
          <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
              <EuiText size="s">
                <h5>Deployment Type</h5>
                <span>
                  {deploymentTypes.length > 0 ? deploymentTypes.join(', ') : 'N/A'}
                </span>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiFlexGroup>
    </ElementErrorBoundary>
  );
};

export default DeploymentTypeOptions;
