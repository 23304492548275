import React from 'react';
import Box from "@amzn/awsui-components-react/polaris/box";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { EuiLoadingSpinner, EuiText, EuiSpacer } from '@elastic/eui';
import { apiCall } from '../../../utils';
import { ClusterInStageDetail } from './ClusterInStageDetail';
import { StatusPieChart } from './components/StatusPieChart';
import { StageBarChart } from './components/StageBarChart';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';

export class StageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stageData: null,
      isLoading: true,
      error: null,
      selectedStage: null,
      selectedFilters: ['Success', 'Failed', 'In Progress']
    };
  }

  componentDidMount() {
    this.fetchStageData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.releaseId !== this.props.releaseId ||
      prevProps.stackName !== this.props.stackName) {
      this.fetchStageData();
    }
  }

  fetchStageData = async () => {
    const { releaseId, stackName } = this.props;
    if (!releaseId || !stackName) return;

    this.setState({ isLoading: true });
    try {
      const response = await apiCall('/componentReleaseTracker/stages', {
        releaseId,
        stackName,
        maxResults: 100
      });

      if (response.statusCode === 200) {
        this.setState({
          stageData: response.body,
          error: null
        });
      } else {
        this.setState({
          error: response.body?.message || 'Failed to fetch stage details',
          stageData: null
        });
      }
    } catch (err) {
      console.error('Error fetching stage data:', err);
      this.setState({
        error: err.message || 'An error occurred while fetching stage details',
        stageData: null
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  calculateTotals = () => {
    const { stageData } = this.state;
    if (!stageData?.stages) return { scheduled: 0, success: 0, failed: 0, inProgress: 0 };

    return stageData.stages.reduce(
      (acc, stage) => {
        const inProgress = stage.deployMetrics.nScheduled -
          (stage.deployMetrics.nSuccess + stage.deployMetrics.nFailed);
        return {
          scheduled: acc.scheduled + stage.deployMetrics.nScheduled,
          success: acc.success + stage.deployMetrics.nSuccess,
          failed: acc.failed + stage.deployMetrics.nFailed,
          inProgress: acc.inProgress + inProgress
        };
      },
      { scheduled: 0, success: 0, failed: 0, inProgress: 0 }
    );
  };

  parseStageNumber = (identifier) => {
    if (!identifier) return null;
    const match = String(identifier).match(/Stage\s+(\d+)/);
    return match ? parseInt(match[1], 10) : null;
  };

  handleFilterChange = (e) => {
    const value = e.target.value;
    if (value === 'all') {
      this.setState({ selectedFilters: ['Success', 'Failed', 'In Progress'] });
    } else {
      this.setState({ selectedFilters: value.split(',') });
    }
  };

  handleStageSelect = (stageIdentifier) => {
    const stageNumber = this.parseStageNumber(stageIdentifier);
    if (stageNumber !== null) {
      this.setState({ selectedStage: stageNumber });
    } else {
      console.warn('Invalid stage identifier:', stageIdentifier);
    }
  };

  render() {
    const { isLoading, error, stageData, selectedStage } = this.state;
    const { releaseId, stackName } = this.props;

    if (isLoading) {
      return (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <EuiLoadingSpinner size="xl" />
        </div>
      );
    }

    if (error) {
      return (
        <EuiText color="danger" style={{ padding: '1rem' }}>
          <p>{error}</p>
        </EuiText>
      );
    }

    if (!stageData?.stages?.length) {
      return (
        <EuiText style={{ padding: '1rem' }}>
          <p>No stage data available.</p>
        </EuiText>
      );
    }

    const totals = this.calculateTotals();

    return (
      <ElementErrorBoundary>
        <Container>
          <SpaceBetween size="l">
            <Header
              variant="h2"
              description={`Release ID: ${releaseId}`}
            >
              Stage Details
            </Header>

            <ColumnLayout columns={4} variant="text-grid">
              <Box variant="awsui-key-label">
                Total Scheduled
                <div>{totals.scheduled}</div>
              </Box>
              <Box variant="awsui-key-label">
                Total Success
                <div>{totals.success}</div>
              </Box>
              <Box variant="awsui-key-label">
                Total Failed
                <div>{totals.failed}</div>
              </Box>
              <Box variant="awsui-key-label">
                Success Rate
                <div>
                  {((totals.success / totals.scheduled) * 100).toFixed(1)}%
                </div>
              </Box>
            </ColumnLayout>

            <ColumnLayout columns={2}>
              <StatusPieChart stageData={stageData} />
              <StageBarChart
                stageData={stageData}
                selectedFilters={this.state.selectedFilters}
                onFilterChange={this.handleFilterChange}
                onStageSelect={this.handleStageSelect}
              />
            </ColumnLayout>
          </SpaceBetween>
          {(selectedStage === 0 || selectedStage) && (
            <>
              <EuiSpacer size="l" />
              <ClusterInStageDetail
                releaseId={releaseId}
                stackName={stackName}
                stageId={selectedStage}
              />
            </>
          )}
        </Container>
      </ElementErrorBoundary>
    );
  }
}
