import { gql, useLazyQuery } from '@apollo/client';

interface ReplicaRecord {
  replica_index: number;
  replica_state: string;
  worker_id: string;
  worker_generation: number;
  writer_type: string;
  shard_id: string;
  shard_state: string;
}

interface ReplicaItem {
  record: ReplicaRecord;
  table_id: number;
}

export interface ListReplicasVars {
  accountId: string;
  namespace: string;
  dataPlaneType: string;
  placementConstraintId: string;
  shardLifeCycleStates?: string[];
  replicaStates?: string[];
}

interface ListReplicasResponse {
  listReplicas: ReplicaItem[];
}

const LIST_REPLICAS = gql`
  query ListReplicas(
    $accountId: String!
    $namespace: String!
    $dataPlaneType: String!
    $placementConstraintId: String!
    $shardLifeCycleStates: [String]
    $replicaStates: [String]
  ) {
    listReplicas(
      accountId: $accountId
      namespace: $namespace
      dataPlaneType: $dataPlaneType
      placementConstraintId: $placementConstraintId
      shardLifeCycleStates: $shardLifeCycleStates
      replicaStates: $replicaStates
    ) {
      record {
        replica_index
        replica_state
        worker_id
        worker_generation
        writer_type
        shard_id
        shard_state
      }
      table_id
    }
  }
`;

export const useListReplicasQuery = () => {
  return useLazyQuery<ListReplicasResponse, ListReplicasVars>(
    LIST_REPLICAS,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    }
  );
};

export type { ReplicaItem, ReplicaRecord };