import { gql, useLazyQuery } from '@apollo/client';
import { AnyObject } from '../../../models/types';

interface JunoGetApiVars {
  awsAccountId: String;
  collectionId: String;
  url: string;
  data?: string;
}

export const JUNO_GET_API = gql`
  query JunoGetApi(
    $awsAccountId: String
    $collectionId: String
    $url: String!
    $data: String
  ) {
    junoGetApi(
      awsAccountId: $awsAccountId
      collectionId: $collectionId
      url: $url
      data: $data
    )
  }
`;

export const useJunoGetApiQuery = () => {
  return useLazyQuery<AnyObject, JunoGetApiVars>(JUNO_GET_API, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  });
};