import {
    Button,
    Container,
    FormField,
    Select,
    Table,
    Header,
    SpaceBetween,
    Multiselect,
    SelectProps,
    PropertyFilter,
    Pagination,
    Box,
    StatusIndicator
} from '@amzn/awsui-components-react';
import { useState, useEffect, useMemo } from 'react';
import { useListReplicasQuery, ReplicaItem } from '../../../hooks/Shardmanager/useListJunoReplicasQuery';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../../utils/constants';
import { EmptyState, getFilterCounterText, Preferences } from "../../../../utils/tablePreferences";

interface ListReplicasProps {
    awsAccountId: string;
    placementConstraintId: string;
    namespace: string;
}

type Option = SelectProps.Option;

const columnDefinitions = [
    { id: 'replica_index', header: 'Replica Index', cell: (item) => item.replica_index ?? '-', sortingField: 'replica_index' },
    { id: 'replica_state', header: 'Replica State', cell: (item) => item.replica_state ?? '-', sortingField: 'replica_state' },
    { id: 'worker_id', header: 'Worker ID', cell: (item) => item.worker_id ?? '-', sortingField: 'worker_id' },
    { id: 'worker_generation', header: 'Worker Generation', cell: (item) => item.worker_generation ?? '-', sortingField: 'worker_generation' },
    { id: 'writer_type', header: 'Writer Type', cell: (item) => item.writer_type ?? '-', sortingField: 'writer_type' },
    { id: 'shard_id', header: 'Shard ID', cell: (item) => item.shard_id ?? '-', sortingField: 'shard_id' },
    { id: 'shard_state', header: 'Shard State', cell: (item) => item.shard_state ?? '-', sortingField: 'shard_state' }
];

const FILTERING_PROPERTIES = columnDefinitions.map(col => ({
    propertyLabel: col.header,
    key: col.id,
    groupValuesLabel: `${col.header} values`,
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<=']
}));

const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Main properties',
        options: columnDefinitions.map(col => ({ id: col.id, label: col.header }))
    }
];

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Replicas' },
    { value: 20, label: '20 Replicas' },
    { value: 50, label: '50 Replicas' },
    { value: 100, label: '100 Replicas' },
];

const DEFAULT_PREFERENCES = {
    pageSize: 20,
    visibleContent: columnDefinitions.map(col => col.id),
    wraplines: true,
};

const ListReplicas: React.FC<ListReplicasProps> = ({ awsAccountId, placementConstraintId, namespace }) => {
    const [dataPlaneType, setDataPlaneType] = useState<string>('indexing');
    const [shardLifeCycleStates, setShardLifeCycleStates] = useState<Option[]>([{ value: 'Active', label: 'Active' }]);
    const [replicaStates, setReplicaStates] = useState<Option[]>([]);
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [isAutoExecuting, setIsAutoExecuting] = useState(false);
    const [refreshStatus, setRefreshStatus] = useState('');

    const [executeListReplicas, { loading, error, data }] = useListReplicasQuery();

    const shardLifeCycleOptions: Option[] = [
        { value: 'Archived', label: 'Archived' },
        { value: 'Active', label: 'Active' },
        { value: 'Deleted', label: 'Deleted' },
        { value: 'Unpinned', label: 'Unpinned' }
    ];

    const replicaStateOptions: Option[] = [
        { value: 'Synced', label: 'Synced' },
        { value: 'Syncing', label: 'Syncing' },
        { value: 'Unassigned', label: 'Unassigned' }
    ];

    const tableItems = useMemo(() => {
        if (data?.listReplicas) {
            return Array.isArray(data.listReplicas)
                ? data.listReplicas.map(item => item.record)
                : [];
        }
        return [];
    }, [data]);

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(tableItems, {
        propertyFiltering: {
            filteringProperties: FILTERING_PROPERTIES,
            empty: <EmptyState title="No replicas" subtitle="No replicas to display." action={<span></span>} />,
            noMatch: (
                <EmptyState
                    title="No matches"
                    subtitle="We can't find a match."
                    action={<Button onClick={() => actions.setPropertyFiltering({ tokens: [], operation: 'and' })}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
    });

    const handleExecute = async () => {
        setRefreshStatus('Refreshing...');
        try {
            await executeListReplicas({
                variables: {
                    accountId: awsAccountId,
                    placementConstraintId,
                    namespace,
                    dataPlaneType,
                    ...(shardLifeCycleStates.length > 0 && {
                        shardLifeCycleStates: shardLifeCycleStates.map(state => state.value)
                    }),
                    ...(replicaStates.length > 0 && {
                        replicaStates: replicaStates.map(state => state.value)
                    })
                }
            });
            setRefreshStatus('Refreshed');
        } catch (err) {
            console.error('Error executing list replicas:', err);
            setRefreshStatus('Error refreshing');
        }
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const executeWithCurrentValues = () => {
            setRefreshStatus('Refreshing...');
            executeListReplicas({
                variables: {
                    accountId: awsAccountId,
                    placementConstraintId,
                    namespace,
                    dataPlaneType,
                    ...(shardLifeCycleStates.length > 0 && {
                        shardLifeCycleStates: shardLifeCycleStates.map(state => state.value)
                    }),
                    ...(replicaStates.length > 0 && {
                        replicaStates: replicaStates.map(state => state.value)
                    })
                }
            });
            setRefreshStatus('Refreshed');
        };

        if (isAutoExecuting) {
            intervalId = setInterval(executeWithCurrentValues, 20000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        isAutoExecuting,
        dataPlaneType,
        shardLifeCycleStates,
        replicaStates,
        executeListReplicas
    ]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                setIsAutoExecuting(true);
            } else {
                setIsAutoExecuting(false);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        handleVisibilityChange();

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <Container>
            <SpaceBetween direction="vertical" size="l">
                {refreshStatus && (
                    <Box margin={{ bottom: 's' }}>
                        <StatusIndicator type={refreshStatus === 'Error refreshing' ? 'error' : 'success'}>
                            {refreshStatus}
                        </StatusIndicator>
                    </Box>
                )}
                <FormField label="Data Plane Type">
                    <Select
                        selectedOption={{
                            value: dataPlaneType,
                            label: dataPlaneType.charAt(0).toUpperCase() + dataPlaneType.slice(1)
                        }}
                        onChange={({ detail }) => setDataPlaneType(detail.selectedOption.value)}
                        options={[
                            { value: 'search', label: 'Search' },
                            { value: 'indexing', label: 'Indexing' }
                        ]}
                    />
                </FormField>
                <FormField label="Shard Life Cycle States">
                    <Multiselect
                        selectedOptions={shardLifeCycleStates}
                        onChange={({ detail }) => setShardLifeCycleStates([...detail.selectedOptions])}
                        options={shardLifeCycleOptions}
                        placeholder="Choose shard life cycle states"
                        selectedAriaLabel="Selected"
                    />
                </FormField>
                <FormField label="Replica States">
                    <Multiselect
                        selectedOptions={replicaStates}
                        onChange={({ detail }) => setReplicaStates([...detail.selectedOptions])}
                        options={replicaStateOptions}
                        placeholder="Choose replica states"
                        selectedAriaLabel="Selected"
                    />
                </FormField>
                <Button onClick={handleExecute} loading={loading}>
                    Execute
                </Button>
                <Table
                    {...collectionProps}
                    columnDefinitions={columnDefinitions}
                    items={items}
                    loading={loading}
                    loadingText="Loading replicas..."
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No replicas</b>
                            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                                {loading ? "Loading replicas..." : "No replicas to display"}
                            </Box>
                        </Box>
                    }
                    header={
                        <Header
                            counter={`(Showing ${items.length} of ${filteredItemsCount})`}
                            actions={
                                <PropertyFilter
                                    {...propertyFilterProps}
                                    filteringPlaceholder="Find replicas"
                                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                                    countText={getFilterCounterText(filteredItemsCount)}
                                />
                            }
                        >
                            Shard Details
                        </Header>
                    }
                    pagination={<Pagination {...paginationProps} />}
                    preferences={
                        <Preferences
                            preferences={preferences}
                            setPreferences={setPreferences}
                            disabled={false}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                        />
                    }
                    wrapLines={preferences.wraplines}
                    visibleColumns={preferences.visibleContent}
                    resizableColumns
                    stickyHeader
                    variant="full-page"
                />
            </SpaceBetween>
        </Container>
    );
};

export { ListReplicas };