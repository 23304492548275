export const STATUS_COLOR = {
  IN_PROGRESS: 'warning',
  SCHEDULED: 'primary',
  SUCCESS: 'success',
  FAILED: 'danger',
  PAUSED: 'subdued',
  CANCELLED: 'danger',
  PENDING: 'primary'
};

export const STATUS_OPTIONS = [
  { value: 'IN_PROGRESS', text: 'In Progress' },
  { value: 'SCHEDULED', text: 'Scheduled' },
  { value: 'SUCCESS', text: 'Success' },
  { value: 'FAILED', text: 'Failed' },
  { value: 'PAUSED', text: 'Paused' },
  { value: 'CANCELLED', text: 'Cancelled' },
  { value: 'PENDING', text: 'Pending' }
];
