import React from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiToolTip
} from '@elastic/eui';
import ElementErrorBoundary from '../../../../components/ErrorBoundary/ElementErrorBoundary';
import { SOFTWARE_UPDATE_CONSTANTS } from '../../constants';

export const AvailableStackReleaseSummary = ({ availableData = {} }) => {
  const renderSimpleValue = (value) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value === 'boolean') return value.toString();
    return value;
  };

  return (
    <ElementErrorBoundary>
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Information about the available stack release software">
                <strong>Available Stack Release Software</strong>
              </EuiToolTip>
            </h4>
          </EuiText>
        </EuiFlexItem>

        <div style={{ marginLeft: '24px' }}>
          <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
              <EuiText size="s">
                <h5>Current Release Version</h5>
                <span>
                  {renderSimpleValue(availableData?.releaseVersion)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Current Release CSV</h5>
                <span>
                  {availableData?.componentSetVersion ?
                    `${availableData.componentSetVersion.componentSetName} ${availableData.componentSetVersion.majorVersion}.${availableData.componentSetVersion.minorVersion}`
                    : 'N/A'}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Deployment ID</h5>
                <span>
                  {renderSimpleValue(availableData?.deploymentId)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Status</h5>
                <span style={{ color: SOFTWARE_UPDATE_CONSTANTS.TEXT_COLOR[availableData?.status] || 'black' }}>
                  {renderSimpleValue(availableData?.status)}
                </span>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiFlexGroup>
    </ElementErrorBoundary>
  );
};

export default AvailableStackReleaseSummary;
