import { EuiHealth } from '@elastic/eui';
import React from 'react';
import { getHumanReadableSize } from './constants';

// Checks if the index has skew or not and returns the EuiHealth accordingly
export const getIndexHealth = (name: string) => {
  let color;
  if (name.includes('hasSkew')) {
    color = '#F5A700';
    return (
      <span>
        <EuiHealth color={color}></EuiHealth>
        {name.substring(0, name.indexOf('hasSkew'))}{' '}
      </span>
    );
  } else {
    color = '#D9D9D9';
    return (
      <span>
        <EuiHealth color={color}></EuiHealth>
        {name}{' '}
      </span>
    );
  }
};

// Columns for Skew Analysis tables
export const nodeShardCountColumns = [
  {
    field: 'nodeName',
    id: 'nodeName',
    name: 'Node',
    header: 'Node',
    sortable: true,
    cell: (e) => e.nodeName,
  },
  {
    field: 'shardCount',
    id: 'shardCount',
    name: 'Shard count',
    header: 'Shard count',
    sortable: true,
    cell: (e) => e.shardCount,
  },
];

export const nodePrimaryShardCountColumns = [
  {
    field: 'nodeName',
    id: 'nodeName',
    name: 'Node',
    header: 'Node',
    sortable: true,
    cell: (e) => e.nodeName,
  },
  {
    field: 'shardCount',
    id: 'shardCount',
    name: 'Shard count',
    header: 'Primary shard count',
    sortable: true,
    cell: (e) => e.shardCount,
  },
];

export const nodeShardStorageColumns = [
  {
    field: 'nodeName',
    id: 'nodeName',
    name: 'Node',
    header: 'Node',
    sortable: true,
    cell: (e) => e.nodeName,
  },
  {
    field: 'size',
    id: 'size',
    name: 'Size',
    header: 'Size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.size),
  },
];

export const indexShardCountColumns = [
  {
    field: 'indexName',
    id: 'indexName',
    name: 'Index',
    header: 'Index',
    sortable: true,
    cell: (e) => getIndexHealth(e.indexName),
  },
  {
    field: 'primaryShards',
    id: 'primaryShards',
    name: 'Primary shards',
    header: 'Primary shards',
    sortable: true,
    cell: (e) => e.primaryShards,
  },
  {
    field: 'replicaShards',
    id: 'replicaShards',
    name: 'Replica shards',
    header: 'Replica shards',
    sortable: true,
    cell: (e) => e.replicaShards,
  },
  {
    field: 'size',
    id: 'size',
    name: 'Index size',
    header: 'Index size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.size),
  },
  {
    field: 'minVal',
    id: 'minVal',
    name: 'Minimum shard count',
    header: 'Minimum shard count',
    sortable: true,
    cell: (e) => e.minVal,
  },
  {
    field: 'median',
    id: 'median',
    name: 'Median shard count',
    header: 'Median shard count',
    sortable: true,
    cell: (e) => e.median,
  },
  {
    field: 'maxVal',
    id: 'maxVal',
    name: 'Maximum shard count',
    header: 'Maximum shard count',
    sortable: true,
    cell: (e) => e.maxVal,
  },
  {
    field: 'diff',
    id: 'diff',
    name: 'Range',
    header: 'Range',
    sortable: true,
    cell: (e) => e.diff,
  },
  {
    field: 'rangePercent',
    id: 'rangePercent',
    name: 'Range %',
    header: 'Range %',
    sortable: true,
    cell: (e) => e.rangePercent,
  },
];

export const indexPrimaryShardCountColumns = [
  {
    field: 'indexName',
    id: 'indexName',
    name: 'Index',
    header: 'Index',
    sortable: true,
    cell: (e) => getIndexHealth(e.indexName),
  },
  {
    field: 'primaryShards',
    id: 'primaryShards',
    name: 'Primary shards',
    header: 'Primary shards',
    sortable: true,
    cell: (e) => e.primaryShards,
  },
  {
    field: 'size',
    id: 'size',
    name: 'Index size',
    header: 'Index size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.size),
  },
  {
    field: 'minVal',
    id: 'minVal',
    name: 'Minimum shard count',
    header: 'Minimum primary shard count',
    sortable: true,
    cell: (e) => e.minVal,
  },
  {
    field: 'median',
    id: 'median',
    name: 'Median shard count',
    header: 'Median primary shard count',
    sortable: true,
    cell: (e) => e.median,
  },
  {
    field: 'maxVal',
    id: 'maxVal',
    name: 'Maximum shard count',
    header: 'Maximum primary shard count',
    sortable: true,
    cell: (e) => e.maxVal,
  },
  {
    field: 'diff',
    id: 'diff',
    name: 'Range',
    header: 'Range',
    sortable: true,
    cell: (e) => e.diff,
  },
  {
    field: 'rangePercent',
    id: 'rangePercent',
    name: 'Range %',
    header: 'Range %',
    sortable: true,
    cell: (e) => e.rangePercent,
  },
];

export const indexShardStorageColumns = [
  {
    field: 'indexName',
    id: 'indexName',
    name: 'Index',
    header: 'Index',
    sortable: true,
    cell: (e) => getIndexHealth(e.indexName),
  },
  {
    field: 'primaryShards',
    id: 'primaryShards',
    name: 'Primary shards',
    header: 'Primary shards',
    sortable: true,
    cell: (e) => e.primaryShards,
  },
  {
    field: 'replicaShards',
    id: 'replicaShards',
    name: 'Replica shards',
    header: 'Replica shards',
    sortable: true,
    cell: (e) => e.replicaShards,
  },
  {
    field: 'size',
    id: 'size',
    name: 'Index size',
    header: 'Index size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.size),
  },
  {
    field: 'minVal',
    id: 'minVal',
    name: 'Minimum size',
    header: 'Minimum size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.minVal),
  },
  {
    field: 'median',
    id: 'median',
    name: 'Median size',
    header: 'Median size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.median),
  },
  {
    field: 'maxVal',
    id: 'maxVal',
    name: 'Maximum size',
    header: 'Maximum size',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.maxVal),
  },
  {
    field: 'diff',
    id: 'diff',
    name: 'Range',
    header: 'Range',
    sortable: true,
    cell: (e) => getHumanReadableSize(e.diff),
  },
  {
    field: 'rangePercent',
    id: 'rangePercent',
    name: 'Range %',
    header: 'Range %',
    sortable: true,
    cell: (e) => e.rangePercent,
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'indexName',
        label: 'indexName',
      },
      { id: 'primaryShards', label: 'primaryShards' },
      { id: 'replicaShards', label: 'replicaShards' },
      { id: 'size', label: 'size' },
      { id: 'minVal', label: 'minVal' },
      { id: 'median', label: 'median' },
      { id: 'maxVal', label: 'maxVal' },
      { id: 'diff', label: 'diff' },
      { id: 'rangePercent', label: 'rangePercent' },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    'indexName',
    'primaryShards',
    'replicaShards',
    'size',
    'minVal',
    'median',
    'maxVal',
    'diff',
    'rangePercent',
  ],
  wraplines: false,
};

export const NODE_DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['nodeName', 'shardCount', 'size'],
  wraplines: false,
};

export const NODE_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      {
        id: 'nodeName',
        label: 'nodeName',
      },
      { id: 'shardCount', label: 'shardCount' },
      { id: 'size', label: 'size' },
    ],
  },
];
