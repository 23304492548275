import React from 'react';
import { StatusIndicator, Link } from '@amzn/awsui-components-react/polaris';
import { convertEpochToDate } from '../../../utils';
import { DEPLOYMENT_COLORS, STATUS_TYPES, RELEASE_STATUS_COLOR } from '../constants';

export const getColumns = (onReleaseSelect) => [
  {
    id: 'releaseId',
    header: 'Release ID',
    cell: (item) => {
      if (!item.releaseId) return 'N/A';
      return (
        <Link
          variant="primary"
          onFollow={(e) => {
            e.preventDefault();
            onReleaseSelect(item.releaseId);
          }}
          href="#"
        >
          {item.releaseId}
        </Link>
      );
    },
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => {
      if (!item.status) return 'N/A';
      return (
        <StatusIndicator
          type={getStatusType(item.status)}
          color={DEPLOYMENT_COLORS[item.status] || DEPLOYMENT_COLORS.IN_PROGRESS}
        >
          {item.status}
        </StatusIndicator>
      );
    },
  },
  {
    id: 'createdBy',
    header: 'Created By',
    cell: (item) => {
      if (!item.auditProperties?.createdBy) return 'N/A';
      try {
        const createdBy = item.auditProperties.createdBy;
        const parsed = JSON.parse(createdBy);
        return parsed.id || 'N/A';
      } catch (e) {
        return item.auditProperties.createdBy || 'N/A';
      }
    },
  },
  {
    id: 'creationTime',
    header: 'Creation Time',
    cell: (item) => {
      if (!item.auditProperties?.creationTime) return 'N/A';
      return convertEpochToDate(item.auditProperties.creationTime);
    },
  },
  {
    id: 'lastModificationTime',
    header: 'Last Modified',
    cell: (item) => {
      if (!item.auditProperties?.lastModificationTime) return 'N/A';
      return convertEpochToDate(item.auditProperties.lastModificationTime);
    },
  }
];

const getStatusType = (status) => {
  switch (status) {
    case 'SUCCESS':
      return STATUS_TYPES.SUCCESS;
    case 'FAILED':
    case 'FAILED_HEALTH_CHECK':
    case 'FAILED_TASK_ABORTED':
    case 'FAILED_ROLLBACK_DONE':
    case 'FAILED_VALIDATION':
    case 'ERROR':
      return STATUS_TYPES.ERROR;
    case 'INPROGRESS':
      return STATUS_TYPES.INFO;
    case 'SCHEDULED':
      return STATUS_TYPES.PENDING;
    case 'SKIPPED':
    case 'SKIPPED_RELEASE_STAGE_CANCELLED':
    case 'SKIPPED_UPDATE_IN_PROGRESS':
    case 'SKIPPED_CLUSTER_NOT_FOUND':
    case 'SKIPPED_UPDATE_ALREADY_EXISTS':
      return STATUS_TYPES.WARNING;
    default:
      return STATUS_TYPES.INFO;
  }
};

export const getClusterColumns = () => [
  {
    id: 'cluster',
    header: 'Cluster',
    cell: item => item.cluster,
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => (
      <StatusIndicator
        type={getStatusType(item.status)}
        color={DEPLOYMENT_COLORS[item.status] || DEPLOYMENT_COLORS.INPROGRESS}
      >
        {item.status}
      </StatusIndicator>
    ),
  }
];