export const BREADCRUMBS = Object.freeze({
  TUMBLER: { text: 'OPS Tumbler' },
  DOMAIN_DETAILS: { text: 'DOMAIN_DETAILS' },
  JUNO: {text : 'OpenSearch Serverless'}
});

export interface selectedOptionsType {
  label: string;
  value: string;
}

export interface AutosuggestOptionsType {
  value: string;
}

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text) => `Use: "${text}"`,

  tokenEditorTokenRemoveLabel: 'Remove filter',
  tokenEditorTokenRemoveFromGroupLabel: 'Remove filter from this group',
  tokenEditorAddNewTokenLabel: 'Add new filter',
  tokenEditorAddTokenActionsAriaLabel: 'Token actions',
  tokenEditorAddExistingTokenLabel: token => `Add filter ${token.propertyLabel} ${token.operator} ${token.value} to group`

};
