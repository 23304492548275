import { EuiHealth } from '@elastic/eui';

export const OperationTableColumnDefinition = [
  {
    field: 'operationType',
    name: 'Operation Type',
    sortable: true,
    render: (operationType) => {
      if (!operationType) {
        return <EuiHealth color="danger">Something went wrong</EuiHealth>;
      }
      return operationType;
    }
  },
  {
    field: 'componentAliasList',
    name: 'Component Name',
    render: (componentAliasList) => {
      if (!componentAliasList || componentAliasList.length === 0) return 'N/A';
      return (
        <div>
          {componentAliasList.map((component, index) => (
            <div key={index}>
              {component.componentLogicalName}
            </div>
          ))}
        </div>
      );
    },
    sortable: true,
  },
  {
    field: 'status',
    name: 'Status',
    render: (status) => {
      const color = status === 'FAILED' ? 'danger' : 'success';
      return <EuiHealth color={color}>{status}</EuiHealth>;
    },
    sortable: true,
  },
  {
    field: 'startTime',
    name: 'Start Time',
    sortable: true,
    width: '180px',
    sort: (a, b) => {
      return b.rawStartTime - a.rawStartTime;
    }
  },
  {
    field: 'endTime',
    name: 'End Time',
    sortable: true,
    width: '180px',
  },
  {
    field: 'requestNodeIps',
    name: 'Node IPs',
    render: (ips) => ips?.join(', ') || 'N/A',
  },
  {
    field: 'taskID',
    name: 'Task ID',
    sortable: true,
    width: '300px',
    render: (taskID) => taskID || 'N/A',
  },
  {
    field: 'errorMessage',
    name: 'Error Message',
    render: (errorMessage) => errorMessage || 'N/A',
  }
];
