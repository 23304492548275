import { 
    Button, 
    Container, 
    FormField, 
    Select, 
    Table, 
    Header, 
    SpaceBetween, 
    PropertyFilter,
    Pagination,
    Box
} from '@amzn/awsui-components-react';
import { useState, useEffect, useMemo } from 'react';
import { useWorkerDeploymentReportQuery, WorkerDeploymentRecord } from '../../../hooks/Shardmanager/useWorkerDeploymentReportQuery';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../../utils/constants';
import { EmptyState, getFilterCounterText, Preferences } from "../../../../utils/tablePreferences";

interface WorkerDeploymentReportProps {
    awsAccountId: string;
    placementConstraintId: string;
    namespace: string;
}

const columnDefinitions = [
    { id: 'placementConstraint', header: 'Placement Constraint', cell: (item) => item.placementConstraint ?? '-', sortingField: 'placementConstraint' },
    { id: 'gen', header: 'Generation', cell: (item) => item.gen ?? '-', sortingField: 'gen' },
    { id: 'repsOnOldGen', header: 'Replicas on Old Gen', cell: (item) => item.repsOnOldGen ?? '-', sortingField: 'repsOnOldGen' },
    { id: 'shardsWithNoRepsInNewGen', header: 'Shards With No Reps in New Gen', cell: (item) => item.shardsWithNoRepsInNewGen ?? '-', sortingField: 'shardsWithNoRepsInNewGen' },
    { id: 'duration', header: 'Duration', cell: (item) => item.duration ?? '-', sortingField: 'duration' },
    { id: 'stateUnchangedFor', header: 'State Unchanged For', cell: (item) => item.stateUnchangedFor ?? '-', sortingField: 'stateUnchangedFor' },
    { id: 'reason', header: 'Reason', cell: (item) => item.reason ?? '-', sortingField: 'reason' },
    { id: 'deploymentStatus', header: 'Deployment Status', cell: (item) => item.deploymentStatus ?? '-', sortingField: 'deploymentStatus' }
];

const FILTERING_PROPERTIES = columnDefinitions.map(col => ({
    propertyLabel: col.header,
    key: col.id,
    groupValuesLabel: `${col.header} values`,
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<=']
}));

const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Main properties',
        options: columnDefinitions.map(col => ({ id: col.id, label: col.header }))
    }
];

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Deployment Records' },
    { value: 20, label: '20 Deployment Records' },
    { value: 50, label: '50 Deployment Records' },
    { value: 100, label: '100 Deployment Records' },
];

const DEFAULT_PREFERENCES = {
    pageSize: 20,
    visibleContent: columnDefinitions.map(col => col.id),
    wraplines: true,
};

const WorkerDeploymentReport: React.FC<WorkerDeploymentReportProps> = ({ awsAccountId, placementConstraintId, namespace }) => {
    const [dataPlaneType, setDataPlaneType] = useState<string>('indexing');
    const [reportFlag, setReportFlag] = useState<string>('moves');
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

    const [executeWorkerDeploymentReport, { loading, error, data }] = useWorkerDeploymentReportQuery();

    const tableItems = useMemo(() => {
        if (data?.workerDeploymentReport) {
            return Array.isArray(data.workerDeploymentReport) 
                ? data.workerDeploymentReport.map(item => item.record) 
                : [];
        }
        return [];
    }, [data]);

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(tableItems, {
        propertyFiltering: {
            filteringProperties: FILTERING_PROPERTIES,
            empty: <EmptyState title="No deployment records" subtitle="No deployment records to display." action={<span></span>} />,
            noMatch: (
                <EmptyState
                    title="No matches"
                    subtitle="We can't find a match."
                    action={<Button onClick={() => actions.setPropertyFiltering({ tokens: [], operation: 'and' })}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
    });

    const handleExecute = async () => {
        try {
            await executeWorkerDeploymentReport({
                variables: {
                    accountId: awsAccountId,
                    placementConstraintId,
                    namespace,
                    dataPlaneType,
                    reportFlag
                }
            });
        } catch (err) {
            console.error('Error executing worker deployment report:', err);
        }
    };

    return (
        <Container>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Data Plane Type">
                    <Select
                        selectedOption={{ 
                            value: dataPlaneType, 
                            label: dataPlaneType.charAt(0).toUpperCase() + dataPlaneType.slice(1) 
                        }}
                        onChange={({ detail }) => setDataPlaneType(detail.selectedOption.value)}
                        options={[
                            { value: 'search', label: 'Search' },
                            { value: 'indexing', label: 'Indexing' }
                        ]}
                    />
                </FormField>
                <FormField label="Report Type">
                    <Select
                        selectedOption={{ 
                            value: reportFlag, 
                            label: reportFlag.charAt(0).toUpperCase() + reportFlag.slice(1) 
                        }}
                        onChange={({ detail }) => setReportFlag(detail.selectedOption.value)}
                        options={[
                            { value: 'moves', label: 'Moves' },
                            { value: 'workers', label: 'Workers' }
                        ]}
                    />
                </FormField>
                <Button onClick={handleExecute} loading={loading}>
                    Execute
                </Button>
                
                <Table
                    {...collectionProps}
                    columnDefinitions={columnDefinitions}
                    items={items}
                    loading={loading}
                    loadingText="Loading deployment report..."
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No deployment records</b>
                            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                                {loading ? "Loading deployment report..." : "No deployment records to display"}
                            </Box>
                        </Box>
                    }
                    header={
                        <Header
                            counter={`(Showing ${items.length} of ${filteredItemsCount})`}
                            actions={
                                <PropertyFilter
                                    {...propertyFilterProps}
                                    filteringPlaceholder="Find resources"
                                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                                    countText={getFilterCounterText(filteredItemsCount)}
                                />
                            }
                        >
                            Worker Deployment Report
                        </Header>
                    }
                    pagination={<Pagination {...paginationProps} />}
                    preferences={
                        <Preferences
                            preferences={preferences}
                            setPreferences={setPreferences}
                            disabled={false}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                        />
                    }
                    wrapLines={preferences.wraplines}
                    visibleColumns={preferences.visibleContent}
                    resizableColumns
                    stickyHeader
                    variant="full-page"
                />
            </SpaceBetween>
        </Container>
    );
};

export { WorkerDeploymentReport };