import { gql, useLazyQuery } from '@apollo/client';

interface WorkerDeploymentRecordData {
  placementConstraint: string;
  gen: number;
  repsOnOldGen: number;
  shardsWithNoRepsInNewGen: number;
  duration: string;
  stateUnchangedFor: string;
  reason: string;
  deploymentStatus: string;
}

interface WorkerDeploymentRecord {
  record: WorkerDeploymentRecordData;
  table_id: number;
}

export interface WorkerDeploymentReportVars {
  accountId: string;
  namespace: string;
  dataPlaneType: string;
  placementConstraintId: string;
  reportFlag: string;
}

interface WorkerDeploymentReportResponse {
  workerDeploymentReport: WorkerDeploymentRecord[];
}

const WORKER_DEPLOYMENT_REPORT_QUERY = gql`
  query WorkerDeploymentReport(
    $accountId: String!
    $namespace: String!
    $dataPlaneType: String!
    $placementConstraintId: String!
    $reportFlag: String!
  ) {
    workerDeploymentReport(
      accountId: $accountId
      namespace: $namespace
      dataPlaneType: $dataPlaneType
      placementConstraintId: $placementConstraintId
      reportFlag: $reportFlag
    ) {
      record {
        placementConstraint
        gen
        repsOnOldGen
        shardsWithNoRepsInNewGen
        duration
        stateUnchangedFor
        reason
        deploymentStatus
      }
      table_id
    }
  }
`;

export const useWorkerDeploymentReportQuery = () => {
  return useLazyQuery<WorkerDeploymentReportResponse, WorkerDeploymentReportVars>(
    WORKER_DEPLOYMENT_REPORT_QUERY,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    }
  );
};

export type { WorkerDeploymentRecord, WorkerDeploymentRecordData };