import React from 'react';
import { EuiTabs, EuiTab, EuiSpacer } from '@elastic/eui';
import { OperationList } from '../List/OperationList';
import { ActionHistory } from '../History/ActionHistory';
import { TABS } from '../constants';

export class NodeOperationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TABS.OPERATIONS,
      nodeOperationsList: [],
      lastFetchedTaskId: null
    };
    this.operationListRef = React.createRef();
    this.actionHistoryRef = React.createRef();
  }

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab }, () => {
      if (tab === TABS.OPERATIONS) {
        this.handleRefresh();
      }
    });
  };

  handleRefresh = () => {
    const { activeTab } = this.state;
    if (activeTab === TABS.OPERATIONS && this.operationListRef.current) {
      this.operationListRef.current.refreshListOperations();
    } else if (activeTab === TABS.HISTORY && this.actionHistoryRef.current) {
      this.actionHistoryRef.current.refreshCurrentHistory();
    }
  };

  handleOperationsUpdated = (nodeOperations) => {
    if (nodeOperations?.length > 0) {
      const latestOperation = nodeOperations[0];
      console.log('Selected Operation for History:', latestOperation);

      this.setState({
        nodeOperationsList: nodeOperations,
        lastFetchedTaskId: latestOperation.taskID
      });
    }
  };

  render() {
    const { activeTab, nodeOperationsList, lastFetchedTaskId } = this.state;
    const { stackName, clusterId } = this.props;

    return (
      <div>
        <EuiTabs>
          <EuiTab
            isSelected={activeTab === TABS.OPERATIONS}
            onClick={() => this.handleTabChange(TABS.OPERATIONS)}
          >
            List Node Operations
          </EuiTab>
          <EuiTab
            isSelected={activeTab === TABS.HISTORY}
            onClick={() => this.handleTabChange(TABS.HISTORY)}
          >
            Deployment Action History
          </EuiTab>
        </EuiTabs>
        <EuiSpacer size="l" />
        {activeTab === TABS.OPERATIONS ? (
          <OperationList
            ref={this.operationListRef}
            stackName={stackName}
            clusterId={clusterId}
            onOperationsUpdated={this.handleOperationsUpdated}
          />
        ) : (
          <ActionHistory
            ref={this.actionHistoryRef}
            nodeOperationsList={nodeOperationsList}
            initialTaskId={lastFetchedTaskId}
            stackName={stackName}
            clusterId={clusterId}
          />
        )}
      </div>
    );
  }
}
