import React, { useEffect, useState } from 'react';
import {
  Autosuggest,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Header,
  PropertyFilter,
  Table,
  Pagination,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { CodeViewer } from '../../../../Components/Common/CodeViewer';
import { DISCLOSURE_MESSAGE } from '../../../../constants';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { JUNO_APIS, JUNO_API_OPTIONS, PAGE_SIZE_OPTIONS, CODE_VIEWER_APIS, CAT_API_COLUMNS } from './constants';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useJunoGetApiQuery } from '../../../hooks/Collection/useJunoGetApiQuery';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from '../../../../utils/constants';
import { EmptyState, getFilterCounterText, Preferences } from "../../../../utils/tablePreferences";

interface JunoApiExplorerProps {
  collectionId: string;
  awsAccountId: string;
}

interface JunoApiExplorerUiState {
  apiRoot: { value: string; label: string };
  apiPath: { value: string; label: string };
  index?: string;
}

const JunoApiExplorer = (props: JunoApiExplorerProps) => {
  const [executeJunoApi, { data: apiData, loading: filtered_loading }] = useJunoGetApiQuery();
  const [apiResponse, setApiResponse] = useState<any[]>([]);
  const [columnDefinition, setColumnDefinition] = useState([]);
  const [filterPropertyDefinition, setFilterPropertyDefinition] = useState([]);
  const [preferences, setPreferences] = useState({ pageSize: 500, visibleContent: [], wraplines: true });
  const [visibleContentOptions, setVisibleContentOptions] = useState([]);
  const [formValues, setFormValues] = useState<JunoApiExplorerUiState>({
    apiRoot: { value: '_cat', label: '_cat' },
    apiPath: { value: 'indices', label: 'indices' },
  });
  const [indices, setIndices] = useState<Array<{ value: string; label: string }>>([]);
  const [isFetchingIndices, setIsFetchingIndices] = useState(false);
  const [indicesLoading, setIndicesLoading] = useState(true);

  const getApiPathOptions = () => {
    const apiRoot = formValues.apiRoot.value;
    if (apiRoot === 'index') {
      return JUNO_APIS.index;
    }
    return JUNO_APIS[apiRoot.substring(1)] || [];
  };

  useEffect(() => {
    let isSubscribed = true;
  
    const fetchIndices = async () => {
      if (!isSubscribed) return;
  
      setIsFetchingIndices(true);
      try {
        await handleExecuteApi('/_cat/indices');
        if (isSubscribed) {
          setIsFetchingIndices(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setIsFetchingIndices(false);
          console.error('Failed to fetch indices:', error);
        }
      }
    };
  
    fetchIndices();
  
    return () => {
      isSubscribed = false;
    };
  }, [props.awsAccountId, props.collectionId]);

  const handleExecuteApi = async (customUrl?: string) => {
    setApiResponse([]);
    setColumnDefinition([]);
    setFilterPropertyDefinition([]);
    setVisibleContentOptions([]);
    setPreferences(prev => ({ ...prev, visibleContent: [] }));

    const apiRoot = get(formValues, 'apiRoot.value', '_cat');
    const apiPath = get(formValues, 'apiPath.value', 'indices');
    const index = get(formValues, 'index', '');
    let url = customUrl;
    if (!customUrl) {
      if (apiPath.length) {
        url = `/${apiRoot}/${apiPath}`;
      } 
      else {
        url = `/${apiRoot}`;
      }
    }

    if (apiRoot === 'index' && index) {
      url = `/${index}${apiPath ? `/${apiPath}` : ''}`;
    }

    return executeJunoApi({
      variables: {
        awsAccountId: props.awsAccountId,
        collectionId: props.collectionId,
        url: url,
        data: '',
      },
    });
  };

  const createColumnDefinitions = (columns) => {
    return columns.map((column) => ({
      id: column,
      header: column,
      cell: (item) => {
        const value = item[column];
        return typeof value === 'object' ? JSON.stringify(value) : String(value);
      },
      sortingField: column,
    }));
  };

  const createFilterProperties = (columns) => {
    return columns.map((column) => ({
      propertyLabel: column,
      key: column,
      groupValuesLabel: column,
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<=']
    }));
  };

  const createVisibleContentOptions = (columns) => [
    {
      label: 'Options',
      options: columns.map((c) => {
          return { id: c, label: c };
      }),
    },
  ];

  useEffect(() => {
    if (apiData && apiData.junoGetApi && apiData.junoGetApi.success) {
      let parsedResponse = apiData.junoGetApi.data;
      
      if (isFetchingIndices || (formValues.apiRoot.value === '_cat' && formValues.apiPath.value === 'indices')) {
        let lines;
        if (typeof parsedResponse === 'string') {
          lines = parsedResponse.trim().split('\n');
        } else if (Array.isArray(parsedResponse)) {
          lines = parsedResponse;
        } else {
          console.error('Unexpected response format for indices');
          return;
        }

        const newIndices = lines.map(line => {
          const parts = typeof line === 'string' ? line.trim().split(/\s+/) : line;
          const indexName = parts[1];
          return { value: indexName, label: indexName };
        }).filter(index => index.value);

        setIndices(newIndices);
        setIndicesLoading(false);
        if(isFetchingIndices){
          return;
        }
      }

      let columns = [];
      if (typeof parsedResponse === 'string') {
        const lines = parsedResponse.trim().split('\n');
        if (formValues.apiRoot.value === '_cat' && CAT_API_COLUMNS[formValues.apiPath.value]) {
          columns = CAT_API_COLUMNS[formValues.apiPath.value];
        } else {
          columns = Array(lines[0].trim().split(/\s+/).length).fill('').map((_, i) => `Column ${i + 1}`);
        }

        parsedResponse = lines
          .filter(line => line.trim().length > 0)
          .map(line => {
            const values = line.trim().split(/\s+/);
            return columns.reduce((obj, column, index) => {
              obj[column] = values[index];
              return obj;
            }, {});
          });
      } else if (typeof parsedResponse === 'object' && !Array.isArray(parsedResponse)) {
        columns = Object.keys(parsedResponse);
        parsedResponse = [parsedResponse];
      }

      if (CODE_VIEWER_APIS.includes(formValues.apiRoot.value) ||
        (formValues.apiRoot.value === 'index' && ['_mappings', '_settings'].includes(formValues.apiPath.value))) {
        setApiResponse([parsedResponse]);
        setColumnDefinition([]);
        setFilterPropertyDefinition([]);
        setPreferences(prev => ({ ...prev, visibleContent: [] }));
        setVisibleContentOptions([]);
      } else {
        setApiResponse(parsedResponse);
        setColumnDefinition(createColumnDefinitions(columns));
        setFilterPropertyDefinition(createFilterProperties(columns));
        setPreferences(prev => ({ ...prev, visibleContent: columns }));
        setVisibleContentOptions(createVisibleContentOptions(columns));
      }
    }
  }, [apiData, formValues.apiRoot.value, formValues.apiPath.value]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(apiResponse, {
    propertyFiltering: {
      filteringProperties: filterPropertyDefinition,
      empty: <EmptyState title="No data" subtitle="No data to display." action={<span></span>} />,
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can't find a match."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const isCodeViewerView = (CODE_VIEWER_APIS.includes(formValues.apiRoot.value) ||
    (formValues.apiRoot.value === 'index' && ['_mappings', '_settings'].includes(formValues.apiPath.value))) &&
    apiResponse.length > 0 && apiResponse[0] !== undefined;

  return (
    <Container
      header={
        <div>
          <Flashbar items={[{ type: "error", content: DISCLOSURE_MESSAGE }]} />
        </div>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
          <FormField label={<strong>Root API</strong>}>
            <Select
              selectedOption={formValues.apiRoot}
              onChange={({ detail }) =>
                setFormValues(state => ({
                  ...state,
                  apiRoot: {
                    value: detail.selectedOption.value ?? '',
                    label: detail.selectedOption.label ?? ''
                  },
                  apiPath: { value: '', label: '' },
                  index: undefined
                }))
              }
              options={JUNO_API_OPTIONS}
              selectedAriaLabel="Selected"
              placeholder="Choose root API"
            />
          </FormField>
          {formValues.apiRoot.value === 'index' && (
            <FormField label={<strong>Index</strong>}>
              <Select
                selectedOption={formValues.index ? { value: formValues.index, label: formValues.index } : null}
                onChange={({ detail }) =>
                  setFormValues(state => ({
                    ...state,
                    index: detail.selectedOption.value
                  }))
                }
                options={indices}
                selectedAriaLabel="Selected"
                placeholder={indicesLoading ? "Loading indices..." : "Choose an index"}
                disabled={indicesLoading}
                loadingText="Loading indices..."
              />
            </FormField>
          )}
          <FormField label={<strong>Path</strong>}>
            <Autosuggest
              onChange={({ detail }) =>
                setFormValues(state => ({
                  ...state,
                  apiPath: {
                    value: detail.value,
                    label: detail.value
                  }
                }))
              }
              value={formValues.apiPath.value}
              options={getApiPathOptions()}
              enteredTextLabel={(value) => `Current Path: "${value}"`}
              ariaLabel="Autosuggest for API path"
              placeholder="Choose or create path"
              empty="Choose or create path"
            />
          </FormField>
          <div style={{ marginTop: "26px" }}>
            <Button variant="normal" onClick={() => handleExecuteApi()} loading={filtered_loading}>
              Execute
            </Button>
          </div>
        </Grid>
        {isCodeViewerView ? (
          <CodeViewer
            codeData={JSON.stringify(apiResponse[0], null, 2)}
            language={{ label: 'json', value: 'json' }}
            theme={{ label: 'coy', value: 'coy' }}
          />
        ) : (
          <div style={{ fontFamily: '"Roboto Mono",Consolas,Menlo,Courier,monospace' }}>
            {filtered_loading ? (
              <StatusIndicator type="loading">Loading API response</StatusIndicator>
            ) : apiResponse.length === 0 ? (
              <EmptyState
                title="No data"
                subtitle="No data to display."
                action={<span></span>}
              />
            ) : (
              <Table
                {...collectionProps}
                header={<Header counter={`(Showing ${items.length} of ${filteredItemsCount})`}>API Response</Header>}
                columnDefinitions={columnDefinition}
                items={items}
                loading={filtered_loading}
                loadingText="Loading resources"
                resizableColumns
                stickyHeader
                variant="embedded"
                visibleColumns={preferences.visibleContent}
                empty={
                  <EmptyState
                    title="No resources"
                    subtitle="No resources to display."
                    action={<span></span>}
                  />
                }
                filter={
                  <PropertyFilter
                    {...propertyFilterProps}
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    countText={getFilterCounterText(filteredItemsCount)}
                    filteringAriaLabel="Filter resources"
                    filteringPlaceholder="Find resources"
                  />
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={
                  <Preferences
                    preferences={preferences}
                    setPreferences={setPreferences}
                    disabled={false}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    visibleContentOptions={visibleContentOptions}
                  />
                }
              />
            )}
          </div>
        )}
      </SpaceBetween>
    </Container>
  );
};

export { JunoApiExplorer };