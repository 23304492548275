import React from 'react';
import { EuiCodeBlock, EuiLoadingSpinner, EuiCallOut } from '@elastic/eui';
import { apiCall } from '../../utils';
import {ErrorDisplay} from '../../components/ErrorDisplay';
import ElementErrorBoundary from '../../components/ErrorBoundary/ElementErrorBoundary';

export class ComponentVersionDescribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      response: null
    };
  }

  componentDidMount() {
    this.fetchComponentDescribe();
  }

  fetchComponentDescribe = async () => {
    this.setState({ isLoading: true, isError: false });

    const data = {
      stackName: this.props.stackName,
      componentVersion: {
        componentName: this.props.componentName,
        majorVersion: this.props.majorVersion,
        minorVersion: this.props.minorVersion,
        componentScopeDefinition: {
          componentScope: this.props.componentScope,
          componentScopeValue: this.props.componentScopeValue
        }
      }
    };

    try {
      const response = await apiCall('/component/version/describecv', data);

      if (response.statusCode !== 200 || !response.body) {
        throw new Error('Failed to fetch component details');
      }

      this.setState({
        response: response.body,
        isLoading: false,
        isError: false
      });
    } catch (error) {
      this.setState({
        isError: true,
        isLoading: false,
        response: null
      });
    }
  };

  renderContent() {
    const { response } = this.state;

    return (
      <EuiCodeBlock
        language="json"
        fontSize="m"
        paddingSize="m"
        isCopyable
      >
        {JSON.stringify(response, null, 2)}
      </EuiCodeBlock>
    );
  }

  render() {
    const { isLoading, isError } = this.state;

    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <EuiLoadingSpinner size="xl" />
        </div>
      );
    }

    if (isError) {
      return <ErrorDisplay />;
    }

    return (
      <ElementErrorBoundary>
        {this.renderContent()}
      </ElementErrorBoundary>
    );
  }
}
