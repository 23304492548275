import React from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiToolTip,
  EuiSpacer
} from '@elastic/eui';
import ElementErrorBoundary from '../../../../components/ErrorBoundary/ElementErrorBoundary';
import { SOFTWARE_UPDATE_CONSTANTS } from '../../constants';
import { convertEpochToDate } from '../../../../utils';

export const DeploymentConfig = ({ deploymentConfig = {} }) => {
  const renderSimpleValue = (value) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value === 'boolean') return value.toString();
    return value;
  };

  return (
    <ElementErrorBoundary>
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Configuration details for cluster deployment">
                <strong>Cluster Deployment Config</strong>
              </EuiToolTip>
            </h4>
          </EuiText>
        </EuiFlexItem>

        <div style={{ marginLeft: '24px' }}>
          <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
              <EuiText size="s">
                <h5>Release Version</h5>
                <span>
                  {renderSimpleValue(deploymentConfig.releaseVersion)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Deployment Type</h5>
                <span>
                  {renderSimpleValue(deploymentConfig.deploymentType)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Scheduled Time</h5>
                <span>
                  {!deploymentConfig.scheduledTime ? 'N/A' : convertEpochToDate(deploymentConfig.scheduledTime)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Override CSV</h5>
                <span>
                  {deploymentConfig.overrideCSV ?
                    `${deploymentConfig.overrideCSV.componentSetName}-${deploymentConfig.overrideCSV.majorVersion}-${deploymentConfig.overrideCSV.minorVersion}`
                    : 'N/A'}
                </span>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size="m" />

          <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
              <EuiText size="s">
                <h5>Status</h5>
                <span style={{ color: SOFTWARE_UPDATE_CONSTANTS.TEXT_COLOR[deploymentConfig.status] || 'black' }}>
                  {renderSimpleValue(deploymentConfig.status)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Creation Time</h5>
                <span>
                  {!deploymentConfig.creationTime ? 'N/A' : convertEpochToDate(deploymentConfig.creationTime)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Last Modified</h5>
                <span>
                  {!deploymentConfig.lastModificationTime ? 'N/A' : convertEpochToDate(deploymentConfig.lastModificationTime)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Request Source</h5>
                <span>
                  {renderSimpleValue(deploymentConfig.requestSource)}
                </span>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size="m" />

          <EuiFlexGroup gutterSize="l">
            <EuiFlexItem>
              <EuiText size="s">
                <h5>Start Notification</h5>
                <span>
                  {!deploymentConfig.notificationDetails?.deploymentStartNotificationSentTimestamp ? 'N/A' :
                    convertEpochToDate(deploymentConfig.notificationDetails.deploymentStartNotificationSentTimestamp)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size="s">
                <h5>Complete Notification</h5>
                <span>
                  {!deploymentConfig.notificationDetails?.deploymentCompleteNotificationSentTimestamp ? 'N/A' :
                    convertEpochToDate(deploymentConfig.notificationDetails.deploymentCompleteNotificationSentTimestamp)}
                </span>
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem />
            <EuiFlexItem />
          </EuiFlexGroup>
        </div>
      </EuiFlexGroup>
    </ElementErrorBoundary>
  );
};

export default DeploymentConfig;
