import React, { useState, useEffect } from 'react';
import {
  Header,
  Table,
  Box,
  Pagination,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { apiCall } from '../../../utils';
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';
import { CLUSTER_INITIAL_STATE, CLUSTER_PAGE_SIZE_OPTIONS } from '../constants';
import { getClusterColumns } from './columns';

export const ClusterInStageDetail = ({ stageId, stackName, releaseId }) => {
  const [state, setState] = useState(CLUSTER_INITIAL_STATE);
  const {
    items,
    isLoading,
    error,
    nextToken,
    currentPageIndex,
    pageSize,
    filteringText
  } = state;

  const fetchClusterData = async (token = null) => {
    if (!stageId) return;

    setState(prev => ({ ...prev, isLoading: true }));

    try {
      const payload = {
        stackName,
        releaseId,
        stageId: Number(stageId),
        maxResults: 5000,
        ...(token && { nextToken: token })
      };

      const response = await apiCall('/componentReleaseTracker/clustersInStage', payload);

      if (response.statusCode === 200 && response.body?.clusters) {
        setState(prevState => ({
          ...prevState,
          items: token ? [...prevState.items, ...response.body.clusters] : response.body.clusters,
          nextToken: response.body.nextToken,
          isLoading: false,
          totalItemCount: token
            ? prevState.totalItemCount + response.body.clusters.length
            : response.body.clusters.length,
          hasMoreItems: !!response.body.nextToken
        }));
      } else {
        setState(prev => ({
          ...prev,
          error: 'Failed to fetch cluster details',
          isLoading: false
        }));
      }
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err.message || 'Failed to fetch cluster details',
        isLoading: false
      }));
    }
  };

  useEffect(() => {
    fetchClusterData();
  }, [stageId, stackName, releaseId]);

  const getFilteredItems = () => {
    if (!filteringText) return items;
    return items.filter(item =>
      item.cluster.toLowerCase().includes(filteringText.toLowerCase()) ||
      item.status.toLowerCase().includes(filteringText.toLowerCase())
    );
  };

  const getPagedItems = () => {
    const filteredItems = getFilteredItems();
    const startIndex = (currentPageIndex - 1) * pageSize;
    return filteredItems.slice(startIndex, startIndex + pageSize);
  };

  const handlePageChange = ({ detail }) => {
    const { currentPageIndex: newPageIndex } = detail;
    const requiredItems = newPageIndex * pageSize;
    if (requiredItems > items.length && nextToken) {
      fetchClusterData(nextToken);
    }
    setState(prev => ({ ...prev, currentPageIndex: newPageIndex }));
  };

  const handleFilterChange = ({ detail }) => {
    setState(prev => ({
      ...prev,
      filteringText: detail.filteringText,
      currentPageIndex: 1
    }));
  };

  const filteredItems = getFilteredItems();
  const pagedItems = getPagedItems();

  return (
    <ElementErrorBoundary>
      <Table
        columnDefinitions={getClusterColumns()}
        items={pagedItems}
        loading={isLoading}
        loadingText="Loading clusters"
        variant="embedded"
        sortingDisabled={false}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No clusters to display</b>
          </Box>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Filter data across all fields"
            filteringText={filteringText}
            onChange={handleFilterChange}
            countText={`${filteredItems.length} matches`}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex}
            onChange={handlePageChange}
            pagesCount={Math.ceil(filteredItems.length / pageSize)}
            pageSize={pageSize}
          />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{ pageSize }}
            pageSizePreference={{
              title: "Page size",
              options: CLUSTER_PAGE_SIZE_OPTIONS
            }}
            onConfirm={({ detail }) => {
              setState(prev => ({
                ...prev,
                pageSize: detail.pageSize,
                currentPageIndex: 1
              }));
            }}
          />
        }
        header={
          <Header counter={`(${filteredItems.length})`}>
            Clusters in Stage {stageId}
          </Header>
        }
        wrapLines
        stickyHeader={false}
      />
      {error && <Box color="error">{error}</Box>}
    </ElementErrorBoundary>
  );
};
