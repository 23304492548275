import React, { useState, useEffect } from 'react';
import {
  EuiText,
  EuiLoadingSpinner,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiCodeBlock,
  EuiAccordion,
  EuiSpacer,
  EuiToolTip,
} from '@elastic/eui';
import ValidatorTable from './components/ValidatorTable';
import { SOFTWARE_UPDATE_CONSTANTS } from '../constants';
import { apiCall } from '../../../utils';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';
import DeploymentConfig from './components/DeploymentConfig';
import AvailableStackReleaseSummary from './components/AvailableStackReleaseSummary';
import DeploymentTypeOptions from './components/DeploymentTypeOptions';
import PropTypes from 'prop-types';

export const SoftwareUpdateDetails = ({ stackName, clusterId }) => {
  const [updateDetails, setUpdateDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [vfiTooltipContent, setVfiTooltipContent] = useState(null);

  const fetchComponentDetails = async (componentName, majorVersion, minorVersion) => {
    try {
      const data = {
        stackName,
        componentVersion: {
          componentName,
          majorVersion,
          minorVersion,
          componentScopeDefinition: {
            componentScope: "GLOBAL"
          }
        }
      };

      const response = await apiCall('/component/version/describecv', data);
      return response.body;
    } catch (error) {
      console.error('Error fetching component details:', error);
      return null;
    }
  };

  const fetchUpdateDetails = async () => {
    setIsLoading(true);
    try {
      const response = await apiCall('/softwareUpdateDetails/status', {
        cluster: { stackName, clusterId }
      });

      if (response.statusCode === 200) {
        setUpdateDetails(response.body);
        setIsError(false);
      } else {
        console.error('Error fetching software update details:', response);
        setIsError(true);
        setUpdateDetails(null);
      }
    } catch (error) {
      console.error('Error fetching software update details:', error);
      setIsError(true);
      setUpdateDetails(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdateDetails();
  }, [stackName, clusterId]);

  useEffect(() => {
    const fetchVfiDetails = async () => {
      if (!updateDetails || vfiTooltipContent) return;

      const componentVersions = updateDetails?.availableStackReleaseSoftware?.componentVersionsInSet;
      if (!componentVersions?.length) return;

      const component = componentVersions[0];
      if (!component) return;

      const componentName = component.componentId?.replace('g|', '');
      if (!componentName) return;

      try {
        const componentDetails = await fetchComponentDetails(
          componentName,
          component.majorVersion || '',
          component.minorVersion || ''
        );

        const csv = updateDetails?.availableStackReleaseSoftware?.componentSetVersion;
        if (!csv) {
          setVfiTooltipContent(
            <div style={{ whiteSpace: 'pre-line' }}>
              <strong>CSV Detail:</strong>
              <div style={{ marginLeft: '8px', marginBottom: '8px' }}>
                No CSV details available
              </div>
            </div>
          );
          return;
        }

        if (componentDetails?.componentVersion) {
          const vfiX86 = componentDetails.componentVersion?.componentVersionProperties?.platformProperties?.x86_64?.amiDetails?.componentVfi;
          const vfiArm = componentDetails.componentVersion?.componentVersionProperties?.platformProperties?.aarch64?.amiDetails?.componentVfi;

          const tooltipContent = (
            <div style={{ whiteSpace: 'pre-line' }}>
              <strong>CSV Detail:</strong>
              <div style={{ marginLeft: '8px', marginBottom: '8px' }}>
                {`${csv.componentSetName || 'N/A'} (${csv.majorVersion || 'N/A'}.${csv.minorVersion || 'N/A'})`}
              </div>
              {(!vfiX86 && !vfiArm) ? (
                <div>
                  <strong>VFI Detail:</strong>
                  <div style={{ marginLeft: '8px' }}>
                    No VFI details available
                  </div>
                </div>
              ) : (
                <>
                  <strong>VFI Detail:</strong>
                  {vfiX86 && (
                    <div style={{ marginLeft: '8px' }}>
                      • x86_64: {vfiX86}
                    </div>
                  )}
                  {vfiArm && (
                    <div style={{ marginLeft: '8px' }}>
                      • ARM64: {vfiArm}
                    </div>
                  )}
                </>
              )}
            </div>
          );
          setVfiTooltipContent(tooltipContent);
        }
      } catch (error) {
        setVfiTooltipContent(
          <div style={{ whiteSpace: 'pre-line' }}>
            <strong>Error:</strong>
            <div style={{ marginLeft: '8px' }}>
              {error.message || 'Failed to fetch component details'}
            </div>
          </div>
        );
      }
    };

    fetchVfiDetails();
  }, [updateDetails, vfiTooltipContent]);

  const renderSimpleValue = (value) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value === 'boolean') return value.toString();
    return value;
  };

  const renderSimpleMetrics = () => {
    const formatTooltipContent = () => {
      const csv = updateDetails?.availableStackReleaseSoftware?.componentSetVersion;
      if (!csv) {
        return (
          <div style={{ whiteSpace: 'pre-line' }}>
            <strong>CSV Detail:</strong>
            <div style={{ marginLeft: '8px', marginBottom: '8px' }}>
              No CSV details available
            </div>
            <strong>VFI Detail:</strong>
            <div style={{ marginLeft: '8px' }}>
              No VFI details available (requires CSV information)
            </div>
          </div>
        );
      }

      if (vfiTooltipContent) return vfiTooltipContent;

      return (
        <div style={{ whiteSpace: 'pre-line' }}>
          <strong>CSV Detail:</strong>
          <div style={{ marginLeft: '8px', marginBottom: '8px' }}>
            {`${csv.componentSetName || 'N/A'} (${csv.majorVersion || 'N/A'}.${csv.minorVersion || 'N/A'})`}
          </div>
          <div>Loading VFI details...</div>
        </div>
      );
    };

    return (
      <EuiFlexGroup gutterSize="l">
        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Current status of the software update process">
                <strong>Update Status</strong>
              </EuiToolTip>
            </h4>
            <span style={{ color: SOFTWARE_UPDATE_CONSTANTS.TEXT_COLOR[updateDetails?.updateStatus] || '#69707D' }}>
              {renderSimpleValue(updateDetails?.updateStatus)}
            </span>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="N/A implies domain is on a VFI which was never released stackwide. This can happen due to client or domain level VFI pinning/CSV override.">
                <strong>Current Cluster Release Version</strong>
              </EuiToolTip>
            </h4>
            <span>
              {renderSimpleValue(updateDetails?.currentClusterReleaseVersion)}
            </span>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Indicates if a new software update is available">
                <strong>Is New Update Available</strong>
              </EuiToolTip>
            </h4>
            <span>
              {renderSimpleValue(updateDetails?.isSoftwareUpdateAvailable)}
            </span>
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText size="s">
            <h4>
              <EuiToolTip content="The most recent version available for update">
                <strong>Latest Release Version</strong>
              </EuiToolTip>
            </h4>
            <EuiToolTip
              content={formatTooltipContent()}
              position="bottom"
            >
              <span>
                {renderSimpleValue(updateDetails?.availableStackReleaseSoftware?.releaseVersion)}
              </span>
            </EuiToolTip>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <EuiLoadingSpinner size="xl" />
      </div>
    );
  }

  if (isError) {
    return <ErrorDisplay />;
  }

  return (
    <ElementErrorBoundary>
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          {renderSimpleMetrics()}
        </EuiFlexItem>

        <EuiSpacer size="xxl" />

        <EuiFlexItem>
          <AvailableStackReleaseSummary
            availableData={updateDetails?.availableStackReleaseSoftware}
          />
        </EuiFlexItem>

        <EuiSpacer size="xxl" />

        <EuiFlexItem>
          <DeploymentConfig deploymentConfig={updateDetails?.deploymentConfig} />
        </EuiFlexItem>

        <EuiSpacer size="xxl" />

        <EuiFlexItem>
          <DeploymentTypeOptions deploymentTypeData={updateDetails?.deploymentTypeOptions}
          />
        </EuiFlexItem>

        <EuiSpacer size="xxl" />

        <EuiFlexItem>
          <ValidatorTable validatorResults={updateDetails?.clusterValidatorResult} />
        </EuiFlexItem>

        <EuiSpacer size="xl" />

        <EuiFlexItem>
          <EuiAccordion
            id="rawResponse-accordion"
            buttonContent={
              <EuiText size="s">
                <EuiLink>
                  <i>More Details (Raw API Response)</i>
                </EuiLink>
              </EuiText>
            }
            paddingSize="s"
          >
            <EuiCodeBlock
              language="json"
              fontSize="m"
              paddingSize="m"
              isCopyable
            >
              {JSON.stringify(updateDetails, null, 2)}
            </EuiCodeBlock>
          </EuiAccordion>
        </EuiFlexItem>
      </EuiFlexGroup>
    </ElementErrorBoundary>
  );
};

SoftwareUpdateDetails.propTypes = {
  stackName: PropTypes.string.isRequired,
  clusterId: PropTypes.string.isRequired
};
