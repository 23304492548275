import React from 'react';
import { apiCall } from '../../../utils';
import { renderActionHistory } from '../Action/ActionHistoryColumnDefinition';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';

export class ActionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      selectedTaskId: props.initialTaskId || null,
      deploymentActionResult: null
    };
  }

  componentDidMount() {
    this.initializeHistory();
  }

  componentDidUpdate(prevProps) {
    const { initialTaskId } = this.props;
    if (initialTaskId && initialTaskId !== prevProps.initialTaskId) {
      this.updateTaskId(initialTaskId);
    }
  }

  initializeHistory = () => {
    const { initialTaskId, nodeOperationsList } = this.props;

    if (initialTaskId) {
      this.refreshDeploymentActionHistory(initialTaskId);
    } else if (nodeOperationsList?.length > 0) {
      const latestOperation = nodeOperationsList[0];
      this.updateTaskId(latestOperation.taskID);
    }
  };

  updateTaskId = (taskId) => {
    this.setState({ selectedTaskId: taskId }, () => {
      this.refreshDeploymentActionHistory(taskId);
    });
  };

  refreshDeploymentActionHistory = async (taskId) => {
    if (!taskId) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ isLoading: true, isError: false });

    try {
      const response = await apiCall('/nodeOperation/actionExecutionHistory', {
        taskId
      });

      if (response.statusCode !== 200 || !response.body) {
        throw new Error('Failed to fetch action history');
      }

      this.setState({
        deploymentActionResult: response.body,
        isLoading: false,
        isError: false
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        isError: true,
        deploymentActionResult: null
      });
    }
  };

  refreshCurrentHistory = () => {
    const { selectedTaskId } = this.state;
    if (selectedTaskId) {
      this.refreshDeploymentActionHistory(selectedTaskId);
    }
  };

  handleTaskIdChange = (e) => {
    this.setState({ selectedTaskId: e.target.value });
  };

  renderContent() {
    const { isLoading, isError, selectedTaskId, deploymentActionResult } = this.state;

    return renderActionHistory({
      isLoading,
      isError,
      selectedTaskId,
      deploymentActionResult,
      handleTaskIdChange: this.handleTaskIdChange,
      refreshDeploymentActionHistory: this.refreshDeploymentActionHistory
    });
  }

  render() {
    const { isError } = this.state;

    if (isError) {
      return <ErrorDisplay />;
    }

    return (
      <ElementErrorBoundary>
        {this.renderContent()}
      </ElementErrorBoundary>
    );
  }
}
