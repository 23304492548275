import React, { useState, useEffect } from 'react';
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
} from '@elastic/eui';
import {
  Table,
  Box,
  SpaceBetween,
  Pagination,
  TextFilter,
  CollectionPreferences,
  Header,
} from '@amzn/awsui-components-react/polaris';
import { apiCall } from '../../../utils';
import { getReleaseColumns } from './columns';
import { STATUS_OPTIONS } from './constants';
import PropTypes from 'prop-types';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';

export const SoftwareUpdateTrackerDetails = ({ stackName }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [filteringText, setFilteringText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('IN_PROGRESS');

  const fetchReleases = async (token = null) => {
    setIsLoading(true);
    try {
      const payload = {
        status: selectedStatus,
        stackName,
        maxResults: pageSize,
        nextToken: token
      };

      const response = await apiCall('/external/getallReleases', payload);

      if (response.statusCode === 200) {
        const newItems = response.body.stackReleaseSoftwareConfigList;
        setItems(token ? [...items, ...newItems] : newItems);
        setNextToken(response.body.nextToken);
        setTotalItemCount(token ?
          totalItemCount + newItems.length :
          newItems.length
        );
        setError(null);
      } else {
        setError('Failed to fetch releases');
        setItems([]);
      }
    } catch (error) {
      console.error('Error fetching releases:', error);
      setError('Failed to fetch releases');
      setItems([]);
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    fetchReleases();
  }, [selectedStatus, stackName]);

  const getFilteredItems = () => {
    if (!filteringText) return items;

    const searchText = filteringText.toLowerCase();
    return items.filter(item =>
      item.releaseVersion?.toLowerCase().includes(searchText) ||
      item.stackName?.toLowerCase().includes(searchText) ||
      item.deploymentId?.toLowerCase().includes(searchText)
    );

  };

  if (isLoading && !items.length) {
    return (
      <Box textAlign="center" padding={{ vertical: "l" }}>
        <SpaceBetween size="xs">
          <div>Loading...</div>
        </SpaceBetween>
      </Box>
    );
  }

  if (error) {
    return <EuiText color="danger">{error}</EuiText>;
  }

  const filteredItems = getFilteredItems();
  const startIndex = (currentPageIndex - 1) * pageSize;
  const paginatedItems = filteredItems.slice(startIndex, startIndex + pageSize);

  return (
    <ElementErrorBoundary>
      <SpaceBetween size="l">
        <EuiFlexGroup gutterSize="m" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiSelect
                options={STATUS_OPTIONS}
                value={selectedStatus}
                onChange={({ target }) => {
                  setSelectedStatus(target.value);
                  setCurrentPageIndex(1);
                  setNextToken(null);
                }}
                aria-label="Select status"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <Table
          columnDefinitions={getReleaseColumns()}
          items={paginatedItems}
          loading={isLoading}
          loadingText="Loading releases..."
          filter={
            <TextFilter
              filteringPlaceholder="Search releases..."
              filteringText={filteringText}
              onChange={({ detail }) => {
                setFilteringText(detail.filteringText);
                setCurrentPageIndex(1);
              }}
              countText={`${filteredItems.length} matches`}
            />
          }
          pagination={
            <Pagination
              currentPageIndex={currentPageIndex}
              onChange={({ detail }) => {
                setCurrentPageIndex(detail.currentPageIndex);
                if (detail.currentPageIndex * pageSize > items.length && nextToken) {
                  fetchReleases(nextToken);
                }
              }}
              pagesCount={Math.ceil(filteredItems.length / pageSize)}
              pageSize={pageSize}
            />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{ pageSize }}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 10, label: "10 releases" },
                  { value: 20, label: "20 releases" },
                  { value: 50, label: "50 releases" }
                ]
              }}
              onConfirm={({ detail }) => {
                setPageSize(detail.pageSize);
                setCurrentPageIndex(1);
              }}
            />
          }
          empty={
            <Box textAlign="center" color="inherit">
              <b>No releases found</b>
              <Box padding={{ bottom: "s" }}>
                No releases match the current criteria
              </Box>
            </Box>
          }
          header={
            <Header counter={`(${filteredItems.length})`}>
              Releases
            </Header>
          }
          variant="embedded"
          stickyHeader={false}
        />
      </SpaceBetween>
    </ElementErrorBoundary>

  );
};

SoftwareUpdateTrackerDetails.propTypes = {
  stackName: PropTypes.string.isRequired
};