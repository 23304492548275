import React, { useState, useEffect } from 'react';
import {
  Header,
  Table,
  Box,
  SpaceBetween,
  Pagination,
  TextFilter,
  CollectionPreferences,
  FormField,
  Input,
  Button,
  StatusIndicator
} from '@amzn/awsui-components-react/polaris';
import { apiCall } from '../../../utils';
import { StageDetail } from './StageDetail';
import PropTypes from 'prop-types';
import { getColumns } from './columns';
import ElementErrorBoundary from '../../../components/ErrorBoundary/ElementErrorBoundary';
import { Grid } from '@amzn/awsui-components-react';

export const ComponentReleaseTrackerDetail = ({ stackName }) => {
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedReleaseId, setSelectedReleaseId] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteringText, setFilteringText] = useState('');
  const [releaseIdInput, setReleaseIdInput] = useState('');
  const [isFetchingStatus, setIsFetchingStatus] = useState(false);

  const columnDefinitions = getColumns((releaseId) => setSelectedReleaseId(releaseId));

  const fetchActiveReleases = async () => {
    if (!stackName) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await apiCall('/componentReleaseTracker/getActiveReleases', {
        stackName: stackName,
      });

      if (response.statusCode === 200) {
        if (response.body?.error) {
          setError(response.body.error || 'Failed to fetch active releases');
        } else if (!response.body) {
          setError('No data received for active releases');
        } else {
          setReleases(response.body.releases || []);
          setError(null);
        }
      } else {
        const errorMessage = response.body?.message || 'Failed to fetch active releases';
        if (response.body?.__type?.includes('ValidationException')) {
          setError(`Validation Error: ${errorMessage}`);
        } else {
          setError(errorMessage);
        }
      }
    } catch (err) {
      const errorMessage = err.body?.message || err.message || 'An error occurred while fetching releases';
      if (err.body?.__type?.includes('ValidationException')) {
        setError(`Validation Error: ${errorMessage}`);
      } else {
        setError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReleaseStatus = async () => {
    if (!releaseIdInput || !stackName) return;

    setIsFetchingStatus(true);
    try {
      const response = await apiCall('/componentReleaseTracker/releaseStatus', {
        stackName,
        releaseId: releaseIdInput
      });

      if (response.statusCode === 200) {
        if (response.body?.error) {
          setError(response.body.error || 'Failed to fetch release status');
        } else if (!response.body) {
          setError('No data received for release status');
        } else {
          const transformedRelease = {
            releaseId: response.body.releaseId,
            status: response.body.status,
            auditProperties: {
              createdBy: response.body.auditProperties?.createdBy || 'N/A',
            },
            creationTime: response.body.auditProperties?.creationTime || 'N/A',
            lastModificationTime: response.body.auditProperties?.lastModificationTime || 'N/A',
            lastModifiedBy: response.body.auditProperties?.lastModifiedBy || 'N/A'
          };

          setReleases(prevReleases => {
            const filteredReleases = prevReleases.filter(
              release => release.releaseId !== releaseIdInput
            );
            return [transformedRelease, ...filteredReleases];
          });
          setReleaseIdInput('');
          setError(null);
        }
      } else {
        const errorMessage = response.body?.message || 'Failed to fetch release status';
        if (response.body?.__type?.includes('ValidationException')) {
          setError(`Validation Error: ${errorMessage}`);
        } else {
          setError(errorMessage);
        }
      }
    } catch (err) {
      const errorMessage = err.body?.message || err.message || 'Failed to fetch release status';
      if (err.body?.__type?.includes('ValidationException')) {
        setError(`Validation Error: ${errorMessage}`);
      } else {
        setError(errorMessage);
      }
    } finally {
      setIsFetchingStatus(false);
    }
  };

  const handleKeyDown = ({ detail }) => {
    if (detail.key === 'Enter' && releaseIdInput && !isFetchingStatus) {
      fetchReleaseStatus();
    }
  };

  useEffect(() => {
    fetchActiveReleases();
  }, [stackName]);

  const getFilteredItems = () => {
    if (!filteringText) return releases;
    const searchText = filteringText.toLowerCase();
    return releases.filter(item => {
      if (item.releaseId?.toLowerCase().includes(searchText)) return true;
      if (item.status?.toLowerCase().includes(searchText)) return true;
      const createdBy = item.auditProperties?.createdBy;
      if (typeof createdBy === 'string' && createdBy.toLowerCase().includes(searchText)) return true;
      if (createdBy?.id?.toLowerCase().includes(searchText)) return true;
      if (item.auditProperties?.creationTime?.toString().includes(searchText)) return true;
      if (item.auditProperties?.lastModificationTime?.toString().includes(searchText)) return true;
      return false;
    });
  };

  const getPagedItems = () => {
    const filteredItems = getFilteredItems();
    const startIndex = (currentPageIndex - 1) * pageSize;
    return filteredItems.slice(startIndex, startIndex + pageSize);
  };

  const filteredItems = getFilteredItems();
  const items = getPagedItems();

  return (
    <ElementErrorBoundary>
      {isLoading ? (
        <Box textAlign="center" padding={{ vertical: "l" }}>
          <SpaceBetween size="xs">
            <div>Loading...</div>
          </SpaceBetween>
        </Box>
      ) : (
        <SpaceBetween size="l">
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
            <FormField label="Release ID">
              <Input
                value={releaseIdInput}
                onChange={({ detail }) => setReleaseIdInput(detail.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter release ID"
              />
            </FormField>
            <Box padding={{ top: "l" }}>
              <Button
                onClick={fetchReleaseStatus}
                loading={isFetchingStatus}
                disabled={!releaseIdInput}
              >
                Fetch Release Status
              </Button>
            </Box>
          </Grid>

          {error && (
            <Box color="text-status-error" padding={{ vertical: "s" }}>
              <SpaceBetween size="xs">
                <StatusIndicator type="error">
                  {error}
                </StatusIndicator>
              </SpaceBetween>
            </Box>
          )}

          <Table
            columnDefinitions={columnDefinitions}
            items={items}
            loading={isLoading}
            loadingText="Loading releases"
            filter={
              <TextFilter
                filteringPlaceholder="Filter data across all fields"
                filteringText={filteringText}
                onChange={({ detail }) => {
                  setFilteringText(detail.filteringText);
                  setCurrentPageIndex(1);
                }}
                countText={`${filteredItems.length} matches`}
              />
            }
            pagination={
              <Pagination
                currentPageIndex={currentPageIndex}
                onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
                pagesCount={Math.ceil(filteredItems.length / pageSize)}
                pageSize={pageSize}
              />
            }
            preferences={
              <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                preferences={{ pageSize }}
                pageSizePreference={{
                  title: "Page size",
                  options: [
                    { value: 10, label: "10 releases" },
                    { value: 20, label: "20 releases" },
                    { value: 50, label: "50 releases" }
                  ]
                }}
                onConfirm={({ detail }) => {
                  setPageSize(detail.pageSize);
                  setCurrentPageIndex(1);
                }}
              />
            }
            empty={
              <Box textAlign="center" color="inherit">
                <b>No active releases found for this stack</b>
              </Box>
            }
            header={
              <Header counter={`(${filteredItems.length})`}>
                Active Releases
              </Header>
            }
            variant="embedded"
            stickyHeader={false}
          />
          {selectedReleaseId && (
            <StageDetail
              releaseId={selectedReleaseId}
              stackName={stackName}
            />
          )}
        </SpaceBetween>
      )}
    </ElementErrorBoundary>
  );
};

ComponentReleaseTrackerDetail.propTypes = {
  stackName: PropTypes.string.isRequired
};
