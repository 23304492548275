import React from 'react';
import { EuiBasicTable, EuiHealth, EuiAccordion, EuiText, EuiToolTip } from '@elastic/eui';
import ElementErrorBoundary from '../../../../components/ErrorBoundary/ElementErrorBoundary';

export const ValidatorTable = ({ validatorResults }) => {
  const columns = [
    {
      field: 'validatorName',
      name: 'Validator Name',
      width: '40%',
      render: (validatorName) => (
        <span>{validatorName || 'N/A'}</span>
      )
    },
    {
      field: 'status',
      name: 'Status',
      width: '20%',
      render: (status) => (
        <EuiHealth color={status === 'SUCCESS' ? 'success' : status === 'FAILED' ? 'danger' : 'warning'}>
          {status || 'N/A'}
        </EuiHealth>
      )
    },
    {
      field: 'statusMessage',
      name: 'Status Message',
      width: '40%',
      render: (message) => message || 'No message'
    }
  ];

  return (
    <ElementErrorBoundary>
      <EuiAccordion
        id="clusterValidatorResult-accordion"
        buttonContent={
          <EuiText size="s">
            <h4>
              <EuiToolTip content="Results from validation checks performed on the cluster">
                <strong>Cluster Validator Result</strong>
              </EuiToolTip>
            </h4>
          </EuiText>
        }
        paddingSize="s"
      >
        <div style={{ padding: '16px' }}>
          <EuiBasicTable
            items={validatorResults || []}
            columns={columns}
            rowHeader="validatorName"
          />
        </div>
      </EuiAccordion>
    </ElementErrorBoundary>
  );
};

export default ValidatorTable;
