import {
  Button,
  FormField,
  Grid,
  Multiselect,
  SelectProps,
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { CloudWatch, CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import './../../../Styles/cloudwatch-dasboard.css';
import { NODE_HEAT_METRICS_OPTIONS, NODE_HEAT_METRICS } from './constants';
import { getNodeHeatMetrics } from './utils';
import { CloudwatchMetrics, MetricDimension } from '../../../Domain/Cloudwatch/model';
import { useListCwMetrics } from './hooks/useListCwMetrics';
import { getDomainIdentifier } from '../../../utils/helpers';
import { isError } from '../../../ErrorHandler/apiErrorHandler';
import { useListCwRootMetrics } from './hooks/useListCwRootMetrics';

const statsOptions: SelectProps.Options = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

interface NodeHeatForm {
  selectedMetrics: SelectProps.Options;
  nodeIds: SelectProps.Options;
  stats: SelectProps.Options;
  isDirty: boolean;
}

interface NodeHeatProps {
  clientId: string;
  domainName: string;
  rootAccountId: string;
  region: string;
  isOptInRegion: boolean;
  initialValues?: NodeHeatForm;
}

const getNodeIds = (dimensions: MetricDimension[][]) => {
  return dimensions
    .map((dimension) => dimension.find((dim) => dim.name === 'NodeId')?.value)
    .filter(Boolean)
    .map((nodeId) => ({ label: nodeId, value: nodeId }));
};

const NodeHeat = (props: NodeHeatProps) => {
  const { loading, data, error } = useListCwMetrics({
    domainIdentifier: getDomainIdentifier(props.clientId, props.domainName),
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const isErrorPresent = isError(error)
  useEffect(() => {
    if ( isErrorPresent ) {
      setErrorMessage("Unable to get Node Ids. Please refresh or try again later");
    }
  }, [isErrorPresent, error]);

  const metrics: CloudwatchMetrics = get(
    data,
    'listMetrics.metrics',
    undefined
  );

  const [metricOptions, setMetricOptions] = useState(NODE_HEAT_METRICS_OPTIONS);
  const cloudWatchProps = {
    region: props.region,
    rootAccountId: props.rootAccountId,
    isOptInRegion: props.isOptInRegion || false,
  };
  const initialValues = props.initialValues
    ? props.initialValues
    : {
      selectedMetrics: [],
      nodeIds: [],
      stats: [{ label: 'Maximum', value: 'Maximum' }],
      isDirty: false,
    };
  const [formValues, setFormValues] = useState<NodeHeatForm>(initialValues);
  const [widgets, setWidgets] = useState<CloudwatchWidget[]>([]);

  let selectedMetricDimensions: MetricDimension[][]
  selectedMetricDimensions = metrics
    ? metrics["CPUUtilization"] || []
    : [];
  const nodeIdOptions = getNodeIds(selectedMetricDimensions);

  const isFormInvalid = () => {
    return (
      formValues.stats.length === 0 ||
      formValues.selectedMetrics.length === 0
    );
  };
  const handleSubmit = useCallback(() => {
    setFormValues((prevFormValues: NodeHeatForm) => ({
      ...prevFormValues,
      isDirty: false,
    }));
    const stats = formValues.stats
      ? formValues.stats.map((stat: SelectProps.Option) =>
        stat ? stat.label : '',
      )
      : ['Maximum'];

    const metricNames = formValues.selectedMetrics ? formValues.selectedMetrics: [];
    const nodeIds = formValues.nodeIds.map((nodeId: SelectProps.Option) =>
      nodeId ? nodeId.label : ''
    );
    const clusterLevelWidgets = getNodeHeatMetrics(
      props.domainName,
      props.clientId,
      nodeIds,
      metricNames,
      stats,
      props.rootAccountId,
      props.region,
      selectedMetricDimensions,
      true,
    ) as CloudwatchWidget[];
    setWidgets(clusterLevelWidgets);
  }, [formValues]);

  useEffect(() => {
    if (props.initialValues) {
      handleSubmit();
    }
  }, [props.initialValues]);

  return (
    <React.Fragment>
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 1 },
        ]}
      >
        <FormField description="Choose Metrics from the list" label="Metric">
          <Multiselect
            selectedOptions={formValues.selectedMetrics}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={metricOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: NodeHeatForm) => ({
                ...prevFormValues,
                selectedMetrics: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField description="Choose stats for the metric" label="Stats">
          <Multiselect
            selectedOptions={formValues.stats}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={statsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: NodeHeatForm) => ({
                ...prevFormValues,
                stats: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField
          description="Choose Nodes IDs to look for metrics."
          label="Node ID"
        >
          <Multiselect
            disabled={nodeIdOptions.length === 0}
            invalid={formValues.stats.length === 0}
            selectedOptions={formValues.nodeIds}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={nodeIdOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            filteringType="auto"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: NodeHeatForm) => ({
                ...prevFormValues,
                nodeIds: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
      {widgets && widgets.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={widgets}
        />
      ) : null}
    </React.Fragment>
  );
};

export { NodeHeat };