export const SOFTWARE_UPDATE_CONSTANTS = {
  TEXT_COLOR: {
    SCHEDULED: '#006BB4',
    IN_PROGRESS: '#BD8B00',
    COMPLETED: '#017D73',
    FAILED: '#BD271E',
    TERMINATED: '#BD271E',
    ABORTED: '#BD271E',
    PENDING: '#BD8B00',
    PENDING_CLUSTER_PROVISION: '#BD8B00',
    NEW: '#006BB4',
    TO_SCHEDULE: '#006BB4',
    QUEUED: '#BD8B00',
    ROLLBACK_IN_PROGRESS: '#BD8B00',
    SUCCESS: '#017D73',
    PARTIAL_SUCCESS: '#BD8B00',
    PRE_CHECK_FAILED: '#BD271E',
    HEALTH_CHECK_FAILED: '#BD271E',
    ROLLBACK_FAILED: '#BD271E',
    ROLLBACK_SUCCESS: '#017D73',
    VALIDATION_FAILED: '#BD271E'
  }
};