export interface GraphDefinition {
  title: string;
  paramSets: Array<{
    stats: string[];
    metricNames: string[];
    unit?: string;
    isRootAccount?: boolean;
  }>;
  mathFunctionParams?: Array<{
    id?: string;
    expression: string;
    label: string;
  }>;
}

export interface MetricDefinitions {
  namespace: string;
  dimensionNames: any;
  graphs: GraphDefinition[];
}

export enum EC2_METRICS_DIMENSIONS {
  INSTANCE_ID = 'InstanceId',
}

enum ES_CARBON_METRICS_DIMENSIONS {
  ACTIVE_AZ = 'ActiveAZ',
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
}

export const ES_CARBON_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_CARBON_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ES_JVM_Memory_Pressure',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['JVMMemoryPressure'],
        },
      ],
    },
    {
      title: 'ES_CPU_Utilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['CPUUtilization'],
        },
      ],
    },
    {
      title: 'ES_SysMemoryUtilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SysMemoryUtilization'],
        },
      ],
    },
    {
      title: 'ES_Free_Storage_Space',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['FreeStorageSpace'],
        },
      ],
    },
    {
      title: 'ES_Search_Rate',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SearchRate'],
        },
      ],
    },
    {
      title: 'ES_Indexing_Rate',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['IndexingRate'],
        },
      ],
    },
    {
      title: 'ES_Latency',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['SearchLatency', 'IndexingLatency'],
        },
      ],
    },
    {
      title: 'ES_Search_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolSearchQueue',
            'ThreadpoolSearchRejected',
            'ThreadpoolSearchThreads',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Index_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolWriteQueue',
            'ThreadpoolWriteRejected',
            'ThreadpoolWriteThreads',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_EBS_Volume_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ReadLatency', 'WriteLatency'],
        },
      ],
    },
    {
      title: 'ES_EBS_Volume_Throughput',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ReadThroughput', 'WriteThroughput'],
        },
      ],
    },
    {
      title: 'ES_EBS_Volume_IOPS',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ReadIOPS', 'WriteIOPS'],
	},
      ],
    },
    {
      title: 'ES_MasterReachableFromNode',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterReachableFromNode'],
        },
      ],
    },
    {
      title: 'DataNodes_Active_Shards',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DataNodesShards.active'],
        },
      ],
    },
    {
      title: 'DataNodes_Initialising_Shards',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DataNodesShards.initialising'],
        },
      ],
    },
    {
      title: 'DataNodes_Relocating_Shards',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DataNodesShards.relocating'],
        },
      ],
    },
    {
      title: 'DataNodes_Unassigned_Shards',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DataNodesShards.unassigned'],
        },
      ],
    },
    {
      title: 'DataNodes',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DataNodes'],
        },
      ],
    }
  ],
};

enum ELB_METRICS_DIMENSIONS {
  LOAD_BALANCER_NAME = 'LoadBalancerName',
}

export const ELB_METRICS: MetricDefinitions = {
  namespace: 'AWS/ELB',
  dimensionNames: ELB_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ELB_Host_Health',
      paramSets: [
        { stats: ['Minimum'], metricNames: ['HealthyHostCount'] },
        { stats: ['Maximum'], metricNames: ['UnHealthyHostCount'] },
      ],
    },
    {
      title: 'ELB_Fatal_Request_Errors',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['HTTPCode_Backend_5XX', 'HTTPCode_ELB_5XX'],
        },
      ],
    },
    {
      title: 'ELB_Customer_Request_Errors',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['HTTPCode_Backend_4XX', 'HTTPCode_ELB_4XX'],
        },
      ],
    },
    {
      title: 'ELB_Request_Count',
      paramSets: [{ stats: ['Sum'], metricNames: ['RequestCount'] }],
    },
    {
      title: 'ELB_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['Latency'],
        },
      ],
    },
    {
      title: 'ELB_Rejected_Connections',
      paramSets: [
        { stats: ['Average'], metricNames: ['SurgeQueueLength'] },
        { stats: ['Sum'], metricNames: ['SpilloverCount'] },
      ],
    },
  ],
};

enum ALB_HOST_METRIC_DIMENSIONS {
  LOAD_BALANCER = 'LoadBalancer',
  TARGET_GROUP = 'TargetGroup',
}

export const ALB_HOST_METRICS: MetricDefinitions = {
  namespace: 'AWS/ApplicationELB',
  dimensionNames: ALB_HOST_METRIC_DIMENSIONS,
  graphs: [
    {
      title: 'ALB_Host_Health',
      paramSets: [
        { stats: ['Minimum'], metricNames: ['HealthyHostCount'] },
        { stats: ['Maximum'], metricNames: ['UnHealthyHostCount'] },
      ],
    },
  ],
};

enum ALB_METRICS_DIMENSIONS {
  LOAD_BALANCER = 'LoadBalancer',
}

export const ALB_METRICS: MetricDefinitions = {
  namespace: 'AWS/ApplicationELB',
  dimensionNames: ALB_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ALB_Fatal_Request_Errors',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['HTTPCode_ELB_5XX_Count', 'HTTPCode_Target_5XX_Count'],
        },
      ],
    },
    {
      title: 'ALB_Customer_Request_Errors',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['HTTPCode_ELB_4XX_Count', 'HTTPCode_Target_4XX_Count'],
        },
      ],
    },
    {
      title: 'ALB_Request_Count',
      paramSets: [{ stats: ['Sum'], metricNames: ['RequestCount'] }],
    },
    {
      title: 'ALB_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['TargetResponseTime'],
        },
      ],
    },
    {
      title: 'ALB_Rejected_Connections',
      paramSets: [{ stats: ['Sum'], metricNames: ['RejectedConnectionCount'] }],
    },
    {
      title: 'TLS_Negotiation_Errors',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['ClientTLSNegotiationErrorCount'],
        },
      ],
    },
  ],
};


enum ES_METRICS_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
}

export const KRAKEN_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ES_Warm_Searchable_Documents',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmSearchableDocuments'],
        },
      ],
    },
    {
      title: 'ES_Warm_Deleted_Documents',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmDeletedDocuments'],
        },
      ],
    },
    {
      title: 'ES_Warm_Storage_Space_Utilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmStorageSpaceUtilization'],
        },
      ],
    },
    {
      title: 'ES_Warm_Search_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmSearchLatency'],
        },
      ],
    },
    {
      title: 'ES_Warm_Search_Rate',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmSearchRate'],
        },
      ],
    },
    {
      title: 'Warm_Query_Success/Failure',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['WarmQuerySuccess', 'WarmQueryFailure'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Active',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KrakenFcActivePercent', 'KrakenFcActiveInBytes'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Used',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KrakenFcTotalInBytes', 'KrakenFcUsedInBytes', 'KrakenFcFullFileUsedInBytes'],
          isRootAccount: true,
        },
      ],
    },

    {
      title: 'Kraken_S3_Thread_Pool',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: [
            'KrakenS3ThreadPoolThreads',
            'KrakenS3ThreadPoolQueue',
            'KrakenS3ThreadPoolActive',
            'KrakenS3ThreadPoolLargest',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Discarded_In_Bytes',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KrakenFcRemovedInBytes', 'KrakenFcEvictedInBytes'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Cache_Hits',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KrakenFcCacheHits', 'KrakenFcFullFileCacheHits'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Cache_Miss',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KrakenFcCacheMiss', 'KrakenFcFullFileCacheMisses'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Fc_Full_File_Counts',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenFcFullFileActiveChunkCount',
            'KrakenFcFullFileActiveFileCount',
            'KrakenFcFullFileUsedFileCount',
            'KrakenFcFullFileEvictedFileCount'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_S3_Request_Count',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: ['KrakenS3RequestCount', 'KrakenS3FullFileRequestCount', 'KrakenS3FullFileMultiPartPartsCount'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_S3_Success_Count',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: ['KrakenS3SuccessCount', 'KrakenS3FullFileSuccessCount', 'KrakenS3FullFileMultiPartSuccessCount'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_S3_Failed_Count',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: ['KrakenS3FailedCount', 'KrakenS3FullFileFailedCount', 'KrakenS3FullFileMultiPartFailedCount'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_S3_Bytes_Downloaded',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: ['KrakenS3BytesDownloaded', 'KrakenS3FullFileBytesDownloaded'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_S3_File_Transfer',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenS3TransferStarted',
            'KrakenS3TransferRetried',
            'KrakenS3FullFileTransferStarted',
            'KrakenS3FullFileTransferRetried',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Full_File_Counts',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenS3FullFileSingleChunkFailedCount',
            'KrakenS3FullFileMultiChunkFailedCount',
            'KrakenS3FullFileSingleChunkSuccessCount',
            'KrakenS3FullFileMultiChunkSuccessCount',
            'KrakenS3FullFileMultiChunkChunkCount',
            'KrakenS3FullFileSingleChunkDeletionFailedCount',
            'KrakenS3FullFileSingleChunkIntegrityFailedCount',
            'KrakenS3FullFileMultiChunkDeletionFailedCount',
            'KrakenS3FullFileMultiChunkIntegrityFailedCount',
            'KrakenS3FullFileMultiChunkMergeFailedCount',
            'KrakenS3FullFileMultiChunkDownloadFailedCount',
            'KrakenS3FullFileMultiChunkEvictedOnCacheFullCount'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Full_File_Bytes_Downloaded',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenS3FullFileSingleChunkBytesDownloaded',
            'KrakenS3FullFileMultiChunkBytesDownloaded',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Full_File_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenS3FullFileSingleChunkDownloadLatency',
            'KrakenS3FullFileSingleChunkIntegrityLatency',
            'KrakenS3FullFileMultiChunkDownloadLatency',
            'KrakenS3FullFileMultiChunkIntegrityLatency',
            'KrakenS3FullFileMultiChunkMergeLatency'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Kraken_Prefetch',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'KrakenPrefetchStoredFieldsSuccessCount',
            'KrakenPrefetchStoredFieldsFailureCount',
            'KrakenPrefetchDocValuesSuccessCount',
            'KrakenPrefetchDocValuesFailureCount',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Catalog_API_Counts',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
            'ColdCatalogGetFailureCount',
            'ColdCatalogGetSuccessCount',
            'ColdCatalogListFailureCount',
            'ColdCatalogListSuccessCount',
            'ColdCatalogDeleteUUIDFailureCount',
            'ColdCatalogDeleteUUIDSuccessCount',
            'ColdCatalogDeleteFailureCount',
            'ColdCatalogDeleteSuccessCount',
            'ColdCatalogUpdateFailureCount',
            'ColdCatalogUpdateSuccessCount',
            'ColdCatalogPublishFailureCount',
            'ColdCatalogPublishSuccessCount',
            'ColdCatalogTotalStorageFailureCount',
            'ColdCatalogTotalStorageSuccessCount',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Catalog_API_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'ColdCatalogGetLatency',
            'ColdCatalogListLatency',
            'ColdCatalogDeleteUUIDLatency',
            'ColdCatalogDeleteLatency',
            'ColdCatalogUpdateLatency',
            'ColdCatalogPublishLatency',
            'ColdCatalogTotalStorageLatency',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'WarmToCold_State_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'WarmToColdMigrationOverallLatency',
            'WarmToColdDetachLatency',
            'WarmToColdMetadataMigrationLatency',
            'WarmToColdProcessingLatency',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ColdToWarm_State_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'ColdToWarmMigrationOverallLatency',
            'ColdToWarmMetadataCleanupLatency',
            'ColdToWarmIndexCreationLatency',
            'ColdToWarmProcessingLatency',
          ],
          isRootAccount: true,
        },
      ],
    },
  ],
};

export const S3_DISCOVERY_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'Cron_Job_Metrics',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ClusterDiscovery:ReadJobFailure', 'ClusterDiscovery:WriteJobFailure'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Startup_Metrics',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ClusterDiscovery:StartupReadJobFailure', 'ClusterDiscovery:StartupWriteJobFailure'],
          isRootAccount: true,
        },
      ],
    },
  ],
};

export const DRAGONSTONE_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'Segments_Upload_Succeeded_Rate_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes/Second',
          metricNames:['SegmentsUploadSucceededBytesRate'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Segments_Upload_Failed_Rate_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes/Second',
          metricNames:['SegmentsUploadFailedBytesRate'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Segments_Download_Succeeded_Rate_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes/Second',
          metricNames:['SegmentsDownloadSucceededBytesRate'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Segments_Download_Failed_Rate_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes/Second',
          metricNames:['SegmentsDownloadFailedBytesRate'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Segments_Remote_Size_Lag',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes',
          metricNames:['SegmentsRemoteSizeLagTotalBytes'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Segments_Remote_Time_Lag',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Milliseconds',
          metricNames:['SegmentsRemoteTimeLag'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Segment_Replication_Bytes_Behind',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes',
          metricNames:['SegmentReplicationMaxBytesBehind', 'SegmentReplicationTotalBytesBehind'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Segment_Replication_Time_Lag',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Milliseconds',
          metricNames:['SegmentReplicationMaxReplicationLag'],
          isRootAccount: true
        },
      ]
    },
    {
      title: 'Translog_Upload_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes/Second',
          metricNames:['TranslogUploadSucceededBytesRate', 'TranslogUploadFailedBytesRate'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Number_Of_Failed_Bytes_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Bytes',
          metricNames:['SegmentsUploadFailedBytes', 'SegmentsDownloadFailedBytes', 'TranslogUploadFailedBytes'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Translog_Total_Uploads_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['TranslogTotalUploadsSucceeded', 'TranslogTotalUploadsFailed'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Thread_Pool_Queue_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['ThreadPoolRemotePurgeQueue', 'ThreadPoolRemoteRefreshRetryQueue', 'ThreadPoolTranslogTransferQueue', 'ThreadPoolUrgentFutureCompletionQueue',
            'ThreadPoolUrgentStreamReaderQueue', 'ThreadPoolPriorityFutureCompletionQueue', 'ThreadPoolPriorityStreamReaderQueue', 
            'ThreadPoolFutureCompletionQueue', 'ThreadPoolStreamReaderQueue'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Thread_Pool_Active_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['ThreadPoolRemotePurgeActive', 'ThreadPoolRemoteRefreshRetryActive', 'ThreadPoolTranslogTransferActive', 'ThreadPoolUrgentFutureCompletionActive',
            'ThreadPoolUrgentStreamReaderActive', 'ThreadPoolPriorityFutureCompletionActive', 'ThreadPoolPriorityStreamReaderActive', 
            'ThreadPoolFutureCompletionActive','ThreadPoolStreamReaderActive'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Thread_Pool_Rejected_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['ThreadPoolRemotePurgeRejected', 'ThreadPoolRemoteRefreshRetryRejected', 'ThreadPoolTranslogTransferRejected', 'ThreadPoolUrgentFutureCompletionRejected', 
            'ThreadPoolUrgentStreamReaderRejected', 'ThreadPoolPriorityFutureCompletionRejected', 'ThreadPoolPriorityStreamReaderRejected', 
            'ThreadPoolFutureCompletionRejected', 'ThreadPoolStreamReaderRejected'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Cluster_State_S3Repositories_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['ClusterStateSuccess', 'ClusterStateFailure', 'ClusterStateRequestRetryCount'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Segments_Translog_S3Repositories_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['DataRepositoriesAggregateRequestSuccess', 'DataRepositoriesAggregateRequestFailures', 'DataRepositoriesAggregateRequestRetryCount'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Remote_Storage_Write_Rejected',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          unit: 'Count',
          metricNames:['SegmentsPressureTotalRejections', 'SegmentReplicationBackpressureTotalRejectedRequests'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Remote_State_Latency_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum', 'Sum'],
          unit: 'Milliseconds',
          metricNames:['RemoteStateUploadLatency'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Remote_State_Failure_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum', 'Sum'],
          unit: 'Count',
          metricNames:['RemoteStateUploadCleanupFailedCount', 'RemoteStateUploadFailedCount', 'ClusterStateFailedCount'],
          isRootAccount: true
        }
      ]
    },
    {
      title: 'Remote_State_Success_Metrics',
      paramSets:[
        {
          stats: ['Average', 'Minimum', 'Maximum', 'Sum'],
          unit: 'Count',
          metricNames:['RemoteStateUploadSuccessCount', 'ClusterStateUpdateCount'],
          isRootAccount: true
        }
      ]
    },
  ],
};

export enum PA_METRICS_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
  SERVICE_NAME = 'ServiceName',
  AGGR = 'aggr',
  RCA_VERSION = 'rca-version'
}

export const PA_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: PA_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'Max_Transport_Thread_Blocked_Time',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MaxTransportThreadBlockedTime'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Max_Transport_Thread_Waited_Time',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MaxTransportThreadWaitedTime'],
          isRootAccount: true,
        },
      ],
    },
  ],
};

export const INDEXING_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ES_Indexing',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['IndexingFailureRate'],
          isRootAccount: true,
        },
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['IndexingRate'],
        },
      ]
    },
    {
      title: 'ES_Merge',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MergesOngoing', 'MergesOngoingSize', 'MergeLatency', 'MergesTotalSize', 'MergeThrottledTime'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Flush',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['FlushRate', 'PeriodicFlushRate', 'FlushLatency'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Refresh',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['RefreshRate', 'RefreshLatency', 'RefreshListeners'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Script',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ScriptCompilations', 'ScriptCacheEvictions', 'ScriptCompilationLimitTriggered'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Translog',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['TranslogSize', 'TranslogUncommittedSize'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Deletions',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DeletionsOngoing', 'DeletionRate', 'DeletionLatency'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Throttled',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MergeThrottledTime', 'IndexingThrottledTime', 'IndexingEngineThrottled'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Process',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['OpenFileDescriptors'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_FieldData',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['FieldDataMemorySize'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Segments',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SegmentsIndexWriterMemory', 'SegmentsBitSetMemory'],
          isRootAccount: true,
        }
      ]
    },
    {
      title: 'ES_Doc_Rest_Status',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DocRestStatus_2XX_Count', 'DocRestStatus_4XX_Count', 'DocRestStatus_5XX_Count'],
          isRootAccount: true,
        }
      ]
    },
  ]
}

export const ES_METRICS: MetricDefinitions = {
  namespace: 'AWS/ES',
  dimensionNames: ES_METRICS_DIMENSIONS,
  graphs: [
    {
      title: 'ES_Nodes',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['Nodes'],
        },
      ],
    },
    {
      title: 'ES_Cluster_Status',
      paramSets: [
        { stats: ['Minimum'], metricNames: ['ClusterStatus.green'] },
        {
          stats: ['Maximum'],
          metricNames: ['ClusterStatus.red', 'ClusterStatus.yellow'],
        },
      ],
    },
    {
      title: 'ES_Cluster_Shards_State',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'Shards.active',
            'Shards.unassigned',
            'Shards.activePrimary',
            'Shards.initializing',
            'Shards.relocating',
            'Shards.delayedUnassigned',
          ],
        },
      ],
    },
    {
      title: 'ES_Data_Plane_UDI_Version',
      paramSets: [
        {
          stats: ['Minimum', 'Maximum'],
          metricNames: ['ConfigVersion'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Data_Plane_Total_Request_Count',
      paramSets: [{ stats: ['Sum'], metricNames: ['ElasticsearchRequests', 'OpenSearchRequests'] }],
    },
    {
      title: 'ES_Data_Plane_Invalid_HostHeader_Request_Count',
      paramSets: [
        { stats: ['Sum'], metricNames: ['InvalidHostHeaderRequests'] },
      ],
    },
    {
      title: 'ES_Searchable_Documents',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SearchableDocuments'],
        },
      ],
    },
    {
      title: 'ES_Deleted_Documents',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DeletedDocuments'],
        },
      ],
    },
    {
      title: 'ES_EBS_Volume_IOPS',
      paramSets: [
        { stats: ['Average'], metricNames: ['ReadIOPS', 'WriteIOPS'] },
      ],
    },
    {
      title: 'ES_EBS_Volume_Latency',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: ['ReadLatency', 'WriteLatency'],
        },
      ],
    },
    {
      title: 'ES_EBS_Volume_Throughput',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: ['ReadThroughput', 'WriteThroughput'],
        },
      ],
    },
    {
      title: 'ES_EBS_Volume_Disk_Queue_Depth',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DiskQueueDepth'],
        },
      ],
    },
    {
      title: 'ES_Automated_Snapshot_Failure',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['AutomatedSnapshotFailure'],
        },
      ],
    },
    {
      title: 'ES_Kibana_Healthy_Nodes',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['KibanaHealthyNodes'],
        },
      ],
    },
    {
      title: 'ES_Pending_Tasks',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['PendingTasks'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_WarmSysMemoryUtilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmSysMemoryUtilization'],
        },
      ],
    },
    {
      title: 'ES_SysMemoryUtilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SysMemoryUtilization'],
        },
      ],
    },
    {
      title: 'ES_Master_JVM_Memory_Pressure',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterJVMMemoryPressure'],
        },
      ],
    },
    {
      title: 'ES_Warm_JVM_Memory_Pressure',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmJVMMemoryPressure'],
        },
      ],
    },
    {
      title: 'ES_JVM_Memory_Pressure',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['JVMMemoryPressure'],
        },
      ],
    },
    {
      title: 'ES_Coordinator_JVM_Memory_Pressure',
      paramSets: [
        {
          stats: ['Average', 'Maximum'],
          metricNames: ['CoordinatorJVMMemoryPressure']
        }
      ]
    },
    {
      title: 'ES_CoordinatorSysMemoryUtilization',
      paramSets: [
        {
        stats: ['Average', 'Maximum'],
        metricNames: ['CoordinatorSysMemoryUtilization']
        }
      ]
    },
    {
      title: 'ES_Coordinator_CPU_Utilization',
      paramSets: [
        {
        stats: ['Average', 'Maximum'],
        metricNames: ['CoordinatorCPUUtilization']
        }
      ]
    },
    {
      title: 'ES_Master_Free_Storage_Space',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterFreeStorageSpace'],
        },
      ],
    },
    {
      title: 'ES_Warm_Free_Storage_Space',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmFreeStorageSpace'],
        },
      ],
    },
    {
      title: 'ES_Free_Storage_Space',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['FreeStorageSpace'],
        },
      ],
    },
    {
      title: 'ES_Master_CPU_Credit_Balance',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterCPUCreditBalance'],
        },
      ],
    },
    {
      title: 'ES_CPU_Credit_Balance',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['CPUCreditBalance'],
        },
      ],
    },
    {
      title: 'ES_Master_CPU_Utilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterCPUUtilization'],
        },
      ],
    },
    {
      title: 'ES_Warm_CPU_Utilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['WarmCPUUtilization'],
        },
      ],
    },
    {
      title: 'ES_CPU_Utilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['CPUUtilization'],
        },
      ],
    },
    {
      title: 'ES_Cluster_Writes_Blocked',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['ClusterIndexWritesBlocked'],
        },
      ],
    },
    {
      title: 'ES_Request_Rate',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['SearchRate', 'IndexingRate'],
        },
      ],
    },
    {
      title: 'ES_Concurrent_Search_Rate',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'ConcurrentSearchRate',
            'WarmConcurrentSearchRate',
          ],
        },
      ],
    },
    {
      title: 'ES_Concurrent_Search_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'ConcurrentSearchLatency',
            'WarmConcurrentSearchLatency',
          ],
        },
      ],
    },
    {
      title: 'ES_Search_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolSearchQueue',
            'ThreadpoolSearchRejected',
            'ThreadpoolSearchThreads',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Thread_Pool_Total_Wait_Time',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolSearchAvgTotalWaitTimeInMillis',
            'ThreadpoolIndexSearcherAvgTotalWaitTimeInMillis',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Index_Searcher_Queue_State',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolIndexSearcherThreads',
            'ThreadpoolIndexSearcherQueue',
            'ThreadpoolIndexSearcherRejected',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Concurrent_Search_Avg_Slice_Count',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ConcurrentSearchAvgSliceCount',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Warm_Thread_Pool_Total_Wait_Time',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'WarmThreadpoolSearchAvgTotalWaitTimeInMillis',
            'WarmThreadpoolIndexSearcherAvgTotalWaitTimeInMillis'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Warm_Index_Searcher_Queue_State',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'WarmThreadpoolIndexSearcherThreads',
            'WarmThreadpoolIndexSearcherQueue',
            'WarmThreadpoolIndexSearcherRejected',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Warm_Concurrent_Search_Avg_Slice_Count',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'WarmConcurrentSearchAvgSliceCount',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Index_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolIndexQueue',
            'ThreadpoolIndexRejected',
            'ThreadpoolWriteRejected',
            'ThreadpoolIndexThreads',
          ],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Force_merge_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolForce_mergeQueue',
            'ThreadpoolForce_mergeRejected',
            'ThreadpoolForce_mergeThreads',
          ],
        },
      ],
    },
    {
      title: 'ES_Bulk_queue_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'ThreadpoolBulkQueue',
            'ThreadpoolBulkRejected',
            'ThreadpoolBulkThreads',
          ],
        },
      ],
    },
    {
      title: 'ES_Asynchronous_search_request_rate',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'AsynchronousSearchInitializedRate',
            'AsynchronousSearchSubmissionRate',
            'AsynchronousSearchCompletionRate',
            'AsynchronousSearchPersistRate',
          ],
        },
      ],
    },
    {
      title: 'ES_Asynchronous_search_failure_rate',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'AsynchronousSearchFailureRate',
            'AsynchronousSearchPersistFailedRate',
          ],
        },
      ],
    },
    {
      title: 'ES_Asynchronous_Search_rejected_state',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'AsynchronousSearchCancelled',
            'AsynchronousSearchRejected',
          ],
        },
      ],
    },
    {
      title: 'ES_GC_Count',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['JVMGCYoungCollectionCount', 'JVMGCOldCollectionCount'],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_GC_Time',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['JVMGCYoungCollectionTime', 'JVMGCOldCollectionTime'],
        },
      ],
      mathFunctionParams: [
        {
          expression: "RATE(METRICS())",
          label: "Rate"
        }
      ]
    },
    {
      title: 'ES_Latency',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: ['SearchLatency', 'IndexingLatency'],
        },
      ],
    },
    {
      title: 'ES_StaleCache',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['StaleCache'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_DICount',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['DICount'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_MasterNodeCount',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['MasterNodeCount'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_UnreachableMasterNode',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['UnreachableMasterNode'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_ZoneUnbalanced',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ZoneUnbalanced'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_VPCProxy_TotalTime',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['VPCProxy.TotalTime'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_VPCProxy_Sessions',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['VPCProxy.Sessions'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_VPCProxy_UnreachableELB',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['VPCProxy.UnreachableELB'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_VPCProxy_ConnectionErrors',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['VPCProxy.ConnectionErrors'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Encryption@Rest_DataPlane_Errors',
      paramSets: [
        {
          stats: ['Minimum', 'Maximum'],
          metricNames: ['KMSESDataPlaneFailure'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Query_Success/Failure',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: ['QuerySuccess', 'QueryFailure'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Indexing_Pressure_State',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'CoordinatingWriteRejected',
            'PrimaryWriteRejected',
            'ReplicaWriteRejected',
          ],
        },
      ],
    },
    {
      title: 'ES_Indexing_Pressure_RejectionBreakup',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'NodeLimitsReachedRejected',
            'NoSuccessfulRequestLimitsRejected',
            'ThroughputDegradationLimitsRejected',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Indexing_Pressure_RejectionBreakup_ShadowMode',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'NodeLimitsReachedRejectedShadowMode',
            'NoSuccessfulRequestLimitsRejectedShadowMode',
            'ThroughputDegradationLimitsRejectedShadowMode',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Search_Backpressure_Cancellation_Breakup',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
            'SearchTaskCancelled',
            'SearchShardTaskCancelled'
          ],
        },
      ],
    },
    {
      title: 'ES_Search_Backpressure_Task_Cancelled_Percentage',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: [
            'SearchTaskCancelledTaskPercentage',
            'SearchShardTaskCancelledTaskPercentage'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Search_Backpressure_SearchTask_Metrics',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
            'SearchTaskCancelled.HeapMemoryLimitBreached',
            'SearchTaskCancelled.CpuCycleLimitBreached',
            'SearchTaskCancelled.ElapsedTimeLimitBreached',
            'SearchTaskCancellationLimitReached',
            'SearchTaskCancellationCount',
            'SearchTaskCurrentCancellationEligibleCount'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Search_Backpressure_SearchShardTask_Metrics',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
            'SearchShardTaskCancelled.HeapMemoryLimitBreached',
            'SearchShardTaskCancelled.CpuCycleLimitBreached',
            'SearchShardTaskCancelled.ElapsedTimeLimitBreached',
            'SearchShardTaskCancellationLimitReached',
            'SearchShardTaskCancellationCount',
            'SearchShardTaskCurrentCancellationEligibleCount'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Search_Backpressure_Mode',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: [
            'SearchBackpressureMonitorOnly',
            'SearchBackpressureEnforced'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'ES_Admission_Control_Rejection_Count',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
              'AdmissionControlGlobalCpuUsageControllerRejected',
              'AdmissionControlRequestSizeControllerRejected',
              'AdmissionControlGlobalJvmmpControllerRejected'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'HotToWarm_Migration_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'HotToWarmMigrationSuccessLatency',
            'HotToWarmMigrationSnapshotLatency',
            'HotToWarmMigrationProcessingLatency',
            'HotToWarmMigrationForceMergeLatency',
          ],
        },
      ],
    },
    {
      title: 'HotToWarm_Migration_Count',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'HotToWarmMigrationSuccessCount',
            'HotToWarmMigrationFailureCount',
          ],
        },
      ],
    },
    {
      title: 'HotToWarm_Migration_Queue_Size',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'HotToWarmMigrationQueueSize',
            'WarmToHotMigrationQueueSize',
          ],
        },
      ],
    },
    {
      title: 'Cold_Migration_Success_Count',
      paramSets: [
        {
          stats: ['Sum'],
          metricNames: [
            'WarmToColdMigrationSuccessCount',
            'ColdToWarmMigrationSuccessCount',
          ],
        },
      ],
    },
    {
      title: 'Cold_Migration_Failed_Count',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'WarmToColdMigrationFailureCount',
            'ColdToWarmMigrationFailureCount',
          ],
        },
      ],
    },
    {
      title: 'Cold_Migration_Latency',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'WarmToColdMigrationLatency',
            'ColdToWarmMigrationLatency',
          ],
        },
      ],
    },
    {
      title: 'Cold_Migration_Queue_Size',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: [
            'WarmToColdMigrationQueueSize',
            'ColdToWarmMigrationQueueSize',
          ],
        },
      ],
    },
    {
      title: 'ColdStorageSpaceUtilization',
      paramSets: [
        {
          stats: ['Average', 'Minimum', 'Maximum'],
          metricNames: ['ColdStorageSpaceUtilization'],
        },
      ],
    },
    {
      title: 'Domain_Availability',
      paramSets: [
        {
          stats: ['Maximum', 'Minimum', 'Average'],
          metricNames: ['DomainAvailability'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Minutely_Domain_Availability',
      paramSets: [
        {
          stats: ['Maximum', 'Minimum', 'Average'],
          metricNames: ['MinutelyDomainAvailability'],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Scroll_Data',
      paramSets: [
        {
          stats: ['Maximum'],
          metricNames: [
            'OpenContexts-BeagleStone',
            'ScrollCurrent-BeagleStone',
            'ScrollTotal-BeagleStone',
          ],
        },
      ],
    },
    {
      title: 'Thread_Count',
      paramSets: [
        {
          stats: ['Maximum', 'Minimum', 'Average'],
          metricNames: [
            'ThreadCount',
          ],
        },
      ],
    },
    {
      title: 'I/O_Wait_Percentage',
      paramSets: [
        {
          stats: ['Maximum', 'Minimum', 'Average'],
          metricNames: [
            'NodeIOWait',
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Request_Cache_Store_Type',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: [
            'RequestCacheStoreType.OnHeap', 
            'RequestCacheStoreType.TieredSpillover'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Request_Cache_Overall_Stats',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: [
            'CacheHits', 
            'CacheEvictions', 
            'CacheHitRatio'
          ],
          isRootAccount: true,
        },
      ],
    },
    {
      title: 'Request_Cache_Tier_Specific_Stats',
      paramSets: [
        {
          stats: ['Average'],
          metricNames: [
            'HeapTierHits', 
            'HeapTierEvictions', 
            'HeapTierHitRatio',
            'DiskTierHits', 
            'DiskTierEvictions', 
            'DiskTierHitRatio'
          ],
          isRootAccount: true,
        },
      ],
    },
  ],
};

export const BEAGLEPOUND_EP: { [key: string]: string } = {
  'ap-northeast-1': 'wc4y7rvirvni4sx7cjh2t67hxa',
  'ap-northeast-2': 'smrb6yctjkqa2xqrqsmatolrka',
  'ap-south-1': 'jlohsn33petaa3gaz7qcw6xqy4',
  'ap-southeast-1': 'rbgdcomjwimfld2bucjbopp2mm',
  'ap-southeast-2': '5xac3z3fv4q3fqd24i3wii7mm4',
  'ca-central-1': 'mzkt6bg3uutt33nqivdg7umda4',
  'eu-central-1': 'abxl5fh64c4lrv25o73gobixwi',
  'eu-west-1': 'dtyd3xpicogkcd63sirg5uivyq',
  'eu-west-2': 'giralpnfbwhd2z3aseh4yu6qri',
  'eu-west-3': '6oi2gmtsfayy2ywokps3m2f5da',
  'us-east-1': 'rgqmcpathf2ha2nnp4wikwvyqe',
  'us-east-2': 'uxuxjey76aolayenlz5yts443i',
  'us-west-2': '4x5umynq6tvogiqebjyvbmg4iu',
};

export const BEAGLEPOUND_OBJ: { [key: string]: string } = {
  // index pattern
  'perfanalyzer*': '09e3b2b0-ff2d-11ea-8b98-97247a4a517e',
};

export const BEAGLEPOUND_DASHBOARD_OBJ: { [key: string]: string } = {
  'perftop - NodeAnalysis': '721054e0-1262-11eb-88ab-03a26f1b3e38',
  'perfTop - ClusterOverview': '50f6b460-130d-11eb-b54d-01640ef3a6bd',
  'perftop - ClusterNetworkMemoryAnalysis':
    '1ba1a8f0-13e5-11eb-b5fa-d311b5ab1a5e',
  'perftop - ClusterThreadAnalysis': 'ac03cba0-13cf-11eb-bba6-519d4e9aaf89',
  'Memory Metrics': 'c73bf270-1943-11eb-adef-6b6a9068f79a',
  'GC Metrics': '93f30cf0-1966-11eb-88ab-03a26f1b3e38',
};

export const METRIC_RETENTION_INFO =[
  "Data points with a period of less than 60 seconds are available for 3 hours",
  "Data points with a period of 60 seconds (1 minute) are available for 15 days",
  "Data points with a period of 300 seconds (5 minute) are available for 63 days",
  "Data points with a period of 3600 seconds (1 hour) are available for 455 days (15 months)"
]

const DAY_TO_HOURS = 24
const HOURS_TO_SECONDS = 3600
const MINUTE_TO_SECONDS = 60

export const HOURS_3 = 3 * HOURS_TO_SECONDS
export const DAYS_15 = 15 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_63 = 63 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_455 = 455 * DAY_TO_HOURS * HOURS_TO_SECONDS

export const SEC_1 = 1
export const MINUTE_1 = 1 * MINUTE_TO_SECONDS
export const MINUTE_5 = 5 * MINUTE_TO_SECONDS
export const MINUTE_15 = 15 * MINUTE_TO_SECONDS
export const HOUR_1 = 1 * HOURS_TO_SECONDS
export const DAY_1 = 1 * DAY_TO_HOURS * HOURS_TO_SECONDS

export enum HA_PROXY_METRIC_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
  INSTANCE_ID = 'InstanceId',
}

export const HAPROXY_METRICS = {
  Native: [
    { label: "Native HAProxy Status", value: "HAProxyStatus" },
    { label: "Native HAProxy CPU Percentage", value: "HAProxyCPUPercentage" },
    { label: "Native HAProxy Memory Usage", value: "HAProxyMemoryUsage" },
  ],
  Containerised: [
    { label: "Containerised HAProxy Status", value: "ContainerisedHAProxyStatus" },
    { label: "Containerised HAProxy CPU Percentage", value: "HAProxyContainerisedCPUPercentage" },
    { label: "Containerised HAProxy Memory Usage", value: "HAProxyContainerisedMemoryUsage" },
    { label: "Container Memory Usage", value: "HAProxyContainerStatsMemoryUsage" },
  ],
  Common: [
    { label: "Current Sessions HAProxy", value: "HAProxyNetworkStatus_Current_Sessions_At_HAProxy" },
    { label: "Current Sessions ESServer", value: "HAProxyNetworkStatus_Current_Sessions_At_ES" },
    { label: "Request Errors", value: "HAProxyNetworkStatus_Request_Errors" },
    { label: "Request Connection Errors", value: "HAProxyNetworkStatus_Number_Of_Req_Connection_Errors" },
    { label: "Response Errors", value: "HAProxyNetworkStatus_Number_Of_Resp_Errors" },
    { label: "5xx Response From ESServer", value: "HAProxyNetworkStatus_HTTP_Resp_With_5xx_Code_Server_ES" },
    { label: "4xx Response From ESServer", value: "HAProxyNetworkStatus_HTTP_Resp_With_4xx_Code_Server_ES" },
    { label: "5xx Response From HAProxyForESBackend", value: "HAProxyNetworkStatus_HTTP_Resp_With_5xx_Code_Backend_ES" },
    { label: "4xx Response From HAProxyForESBackend", value: "HAProxyNetworkStatus_HTTP_Resp_With_4xx_Code_Backend_ES" },
    { label: "Current Queued Requests at ESServer", value: "HAProxyNetworkStatus_Number_Of_Current_Queued_Requests" },
    { label: "Average Response Time Last 1024 Requests", value: "HAProxyNetworkStatus_Average_Response_Time_Over_Last_1024_Requests" },
    { label: "Average Queue Time Last 1024 Requests", value: "HAProxyNetworkStatus_Average_Queue_Time_Over_Last_1024_Requests" },
    { label: "Average Connect Time Last 1024 Requests", value: "HAProxyNetworkStatus_Average_Connect_Time_Over_Last_1024_Requests" }
  ]
}

export enum PROCESS_METRIC_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
  INSTANCE_ID = 'InstanceId',
}

export enum PUBLICATION_METRIC_INSTANCE_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
  INSTANCE_ID = 'InstanceId',
}

export enum PUBLICATION_METRIC_CLUSTER_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName'
}

export enum NODE_HEAT_METRIC_DIMENSIONS {
  CLIENT_ID = 'ClientId',
  DOMAIN_NAME = 'DomainName',
  NODE_ID = 'NodeId',
}

export const PROCESS_METRICS = {
  OS: [
    { label: "OpenSearch CPU Usage", value: "OSProcessCpuUsage" },
    { label: "OpenSearch Dashboard CPU Usage", value: "OSDProcessCpuUsage" },
  ],
  ES: [
    { label: "Elasticsearch CPU Usage", value: "ESProcessCpuUsage" },
    { label: "Kibana CPU Usage", value: "OSDProcessCpuUsage" },
  ],
  Common: [
    { label: "Node CPU Usage", value: "CPUUtilization" },
    { label: "Docker Daemon CPU Usage", value: "DockerDProcessCpuUsage" },
    { label: "Performance Analyzer CPU Usage", value: "PerfAnalyzerProcessCpuUsage" },
    { label: "Beaglestone CPU Usage", value: "BeaglestoneProcessCpuUsage" },
    { label: "Cloudwatch Agent CPU Usage", value: "CWProcessCpuUsage" },
    { label: "KSwapD CPU Usage", value: "kswapdProcessCpuUsage" },
  ]
}

export const EBS_VOLUME_METRICS_OPTIONS = [
    {
    label: 'EBS Burst Balance',
    options: [
      {
        label: 'Root Volume',
        value: 'Root',
        isDisabled: false
      },
      {
        label: 'Log Volume',
        value: 'Log',
        isDisabled: false
      },
        {
        label: 'Swap Volume',
        value: 'Swap',
        isDisabled: false
      },
        {
        label: 'Data Volume',
        value: 'Data',
        isDisabled: false
      }
    ],
  },
  {
    label: 'MicroBursting metrics',
    options: [
      {
        label: 'IOPSMicroBursting',
        value: 'IOPSMicroBursting',
        isDisabled: false
      },
      {
        label: 'ThroughputMicroBursting',
        value: 'ThroughputMicroBursting',
        isDisabled: false
      },  
    ]
  }
]

export const EC2_METRICS_OPTIONS = [
    {
    label: 'EC2 Metrics',
    options: [
      {
        label: 'EC2 Instance CPU',
        value: 'CPUUtilization'
      },
      {
        label: 'EC2 Instance Network In',
        value: 'NetworkIn'
      },
        {
        label: 'EC2 Instance Network Out',
        value: 'NetworkOut'
      },
        {
        label: 'EC2 Instance Disk Writes',
        value: 'DiskWriteBytes'
      }
    ],
  }
]

export const ES_CUSTOMER_METRICS_OPTIONS = [
    {
    label: 'ES Customer Metrics',
    options: [
      {
        label: 'All Metrics',
        value: 'All'
      },
      {
        label: 'General Metrics',
        value: 'General'
      },
      {
        label: 'Data Metrics',
        value: 'Data'
      },
        {
        label: 'Master Metrics',
        value: 'Master'
      },
      {
        label: 'Coordinator Metrics',
        value: 'Coordinator'
      },
        {
        label: 'Ultrawarm Metrics',
        value: 'Ultrawarm'
      },
        {
        label: 'ColdStorage Metrics',
        value: 'ColdStorage'
      },
        {
        label: 'Search Metrics',
        value: 'Search'
      },
        {
        label: 'Indexing Metrics',
        value: 'Indexing'
      },
       {
        label: 'Requests Metrics',
        value: 'Requests'
      }
    ],
  }
]

export const NODE_HEAT_METRICS_OPTIONS = [
  {
    label: 'CPU Heat',
    options: [
      {
        label: 'NodeHeat.ZScore:CPU',
        value: 'NodeHeat.ZScore:CPU'
      },
      /*{
        label: 'CPUUtilization',
        value: 'CPUUtilization'
      },*/
    ]
  },
  {
    label: 'JVM Heat',
    options: [
      {
        label: 'NodeHeat.ZScore:Memory',
        value: 'NodeHeat.ZScore:Memory'
      },
      /*{
        label: 'JVMMemoryPressure',
        value: 'JVMMemoryPressure'
      },*/
    ]
  },
  {
    label: 'Disk Heat',
    options: [
      {
        label: 'NodeHeat.ZScore:DiskIO',
        value: 'NodeHeat.ZScore:DiskIO'
      },
      /*{
        label: 'ReadIOPS',
        value: 'ReadIOPS'
      },
      {
        label: 'WriteIOPS',
        value: 'WriteIOPS'
      },*/
      {
        label: 'NodeHeat.ZScore:DiskUtilization',
        value: 'NodeHeat.ZScore:DiskUtilization'
      },
      /*{
        label: 'FreeStorageSpace',
        value: 'FreeStorageSpace'
      },*/
      {
        label: 'NodeHeat.ZScore:DiskThroughput',
        value: 'NodeHeat.ZScore:DiskThroughput'
      },
      /*{
        label: 'ReadThroughput',
        value: 'ReadThroughput'
      },
      {
        label: 'WriteThroughput',
        value: 'WriteThroughput'
      },*/
    ]
  },
  {
    label: 'Latency Heat',
    options: [
      {
        label: 'NodeHeat.ZScore:IndexingLatency',
        value: 'NodeHeat.ZScore:IndexingLatency'
      },
      /*{
        label: 'IndexingLatency',
        value: 'IndexingLatency'
      },*/
      {
        label: 'NodeHeat.ZScore:SearchLatency',
        value: 'NodeHeat.ZScore:SearchLatency'
      },
      /*{
        label: 'SearchLatency',
        value: 'SearchLatency'
      },*/
    ]
  },
]

export const REMOTE_PUBLICATION_OPTIONS = [
    {
    label: 'Diff Download Metrics',
    options: [
      {
        label: 'RemoteStateDiffDownloadSuccessCount',
        value: 'RemoteStateDiffDownloadSuccessCount'
      },
        {
        label: 'RemoteStateDiffDownloadLatency',
        value: 'RemoteStateDiffDownloadLatency'
      },
        {
        label: 'RemoteStateDiffDownloadFailedCount',
        value: 'RemoteStateDiffDownloadFailedCount'
      }
    ],
  },
    {
    label: 'Full Download Metrics',
    options: [
      {
        label: 'RemoteStateFullDownloadSuccessCount',
        value: 'RemoteStateFullDownloadSuccessCount'
      },
        {
        label: 'RemoteStateFullDownloadLatency',
        value: 'RemoteStateFullDownloadLatency'
      },
        {
        label: 'RemoteStateFullDownloadFailedCount',
        value: 'RemoteStateFullDownloadFailedCount'
      }
    ],
  },
    {
    label: 'Routing table Repo Stats Metrics',
    options: [
      {
        label: 'RoutingTableSuccess',
        value: 'RoutingTableSuccess'
      },
      {
        label: 'RoutingTableRequestRetryCount',
        value: 'RoutingTableRequestRetryCount'
      },
        {
        label: 'RoutingTableFailure',
        value: 'RoutingTableFailure'
      }
    ],
  },
    {
    label: 'Cluster state Repo Stats Metrics',
    options: [
      {
        label: 'ClusterStateSuccess',
        value: 'ClusterStateSuccess'
      },
      {
        label: 'ClusterStateRequestRetryCount',
        value: 'ClusterStateRequestRetryCount'
      },
        {
        label: 'ClusterStateFailure',
        value: 'ClusterStateFailure'
      }
    ],
  },
    {
    label: 'ThreadPool Metrics',
    options: [
      {
        label: 'ThreadPoolRemoteStateReadQueue',
        value: 'ThreadPoolRemoteStateReadQueue'
      },
      {
        label: 'ThreadPoolRemoteStateReadActive',
        value: 'ThreadPoolRemoteStateReadActive'
      },
        {
        label: 'ThreadPoolRemoteStateReadRejected',
        value: 'ThreadPoolRemoteStateReadRejected'
      },
        {
        label: 'ThreadPoolRemoteStateChecksumQueue',
        value: 'ThreadPoolRemoteStateChecksumQueue'
      },
      {
        label: 'ThreadPoolRemoteStateChecksumActive',
        value: 'ThreadPoolRemoteStateChecksumActive'
      },
        {
        label: 'ThreadPoolRemoteStateChecksumRejected',
        value: 'ThreadPoolRemoteStateChecksumRejected'
      },
        {
        label: 'ThreadPoolUrgentFutureCompletionQueue',
        value: 'ThreadPoolUrgentFutureCompletionQueue'
      },
      {
        label: 'ThreadPoolUrgentFutureCompletionActive',
        value: 'ThreadPoolUrgentFutureCompletionActive'
      },
        {
        label: 'ThreadPoolUrgentFutureCompletionRejected',
        value: 'ThreadPoolUrgentFutureCompletionRejected'
      },
        {
        label: 'ThreadPoolRemotePurgeQueue',
        value: 'ThreadPoolRemotePurgeQueue'
      },
      {
        label: 'ThreadPoolRemotePurgeActive',
        value: 'ThreadPoolRemotePurgeActive'
      },
        {
        label: 'ThreadPoolRemotePurgeRejected',
        value: 'ThreadPoolRemotePurgeRejected'
      },
    ],
  },
    {
    label: 'Checksum Metrics',
    options: [
      {
        label: 'RemoteStateFullDownloadChecksumValidationFailedCount',
        value: 'RemoteStateFullDownloadChecksumValidationFailedCount'
      },
      {
        label: 'RemoteStateDiffDownloadChecksumValidationFailedCount',
        value: 'RemoteStateDiffDownloadChecksumValidationFailedCount'
      }
    ],
  },
]

export const CLUSTER_LEVEL_PUBLICATION_METRICS = [
  'RemoteStateUploadSuccessCount', 'RemoteStateUploadLatency',
  'RemoteStateUploadFailedCount', 'RemoteStateUploadCleanupFailedCount', 'RemoteStateIndexRoutingCleanupFailedCount',
  'ClusterStateUpdateLatency', 'RemoteStateDiffDownloadSuccessCount', 'RemoteStateDiffDownloadLatency', 
  'RemoteStateDiffDownloadFailedCount', 'RemoteStateFullDownloadSuccessCount', 'RemoteStateFullDownloadLatency',
  'RemoteStateFullDownloadFailedCount', 'RoutingTableSuccess', 'RoutingTableRequestRetryCount', 'RoutingTableFailure'
  , 'ClusterStateSuccess', 'ClusterStateRequestRetryCount', 'ClusterStateFailure', 'ThreadPoolRemoteStateReadQueue',
  'ThreadPoolRemoteStateReadActive', 'ThreadPoolRemoteStateReadRejected', 'ThreadPoolRemoteStateChecksumQueue', 
  'ThreadPoolRemoteStateChecksumActive', 'ThreadPoolRemoteStateChecksumRejected',
  'ThreadPoolUrgentFutureCompletionQueue', 'ThreadPoolUrgentFutureCompletionActive', 
  'ThreadPoolUrgentFutureCompletionRejected', 'ThreadPoolRemotePurgeQueue', 'ThreadPoolRemotePurgeActive', 
  'ThreadPoolRemotePurgeRejected', 'RemoteStateFullDownloadChecksumValidationFailedCount', 'RemoteStateDiffDownloadChecksumValidationFailedCount'
]

export const NODE_HEAT_METRICS = ['NodeHeat.ZScore:CPU', /*'CPUUtilization', */ 'NodeHeat.ZScore:Memory', /*'JVMMemoryPressure', */ 'NodeHeat.ZScore:DiskIO', /*'ReadIOPS', 'WriteIOPS', */ 'NodeHeat.ZScore:DiskUtilization',  /* 'FreeStorageSpace', */ 'NodeHeat.ZScore:DiskThroughput',/*'ReadThroughput', 'WriteThroughput', */ 'NodeHeat.ZScore:IndexingLatency', /*'IndexingLatency', */ 'NodeHeat.ZScore:SearchLatency', /*'SearchLatency',*/]

export const ES_CUSTOMER_METRICS = {
  General: [
    "ES_Nodes",
    "ES_Cluster_Status",
    "ES_Cluster_Shards_State",
    "ES_DICount",
    "Domain_Availability",
    "Minutely_Domain_Availability",
    "ES_MasterNodeCount",
    "ES_ZoneUnbalanced",
    "ES_Cluster_Writes_Blocked",
    "ES_Searchable_Documents",
    "ES_Deleted_Documents",
    "ES_Kibana_Healthy_Nodes",
    "ES_Data_Plane_UDI_Version",
    "ES_UnreachableMasterNode",
    "ES_Automated_Snapshot_Failure",
    "ES_Encryption@Rest_DataPlane_Errors"
  ],
  Data: [
    "ES_CPU_Credit_Balance",
    "ES_CPU_Utilization",
    "I/O_Wait_Percentage",
    "ES_SysMemoryUtilization",
    "ES_JVM_Memory_Pressure",
    "ES_GC_Count",
    "ES_GC_Time",
    "ES_StaleCache",
    "Thread_Count",
    "ES_EBS_Volume_IOPS",
    "ES_EBS_Volume_Latency",
    "ES_EBS_Volume_Throughput",
    "ES_EBS_Volume_Disk_Queue_Depth",
    "ES_Free_Storage_Space"
  ],
  Master: [
    "ES_Master_CPU_Credit_Balance",
    "ES_Master_CPU_Utilization",
    "ES_Master_JVM_Memory_Pressure",
    "ES_Master_Free_Storage_Space",
    "ES_Pending_Tasks"
  ],
  Coordinator: [
    "ES_Coordinator_JVM_Memory_Pressure",
    "ES_CoordinatorSysMemoryUtilization",
    "ES_Coordinator_CPU_Utilization"
  ],
  Ultrawarm: [
    "ES_Warm_CPU_Utilization",
    "ES_Warm_Thread_Pool_Total_Wait_Time",
    "ES_Warm_Index_Searcher_Queue_State",
    "ES_Warm_Concurrent_Search_Avg_Slice_Count",
    "HotToWarm_Migration_Latency",
    "HotToWarm_Migration_Count",
    "HotToWarm_Migration_Queue_Size",
    "ES_Warm_Free_Storage_Space",
    "ES_WarmSysMemoryUtilization",
    "ES_Warm_JVM_Memory_Pressure",
    "ES_Force_merge_queue_state",
    "ES_Warm_CPU_Utilization"
  ],
  ColdStorage: [
    "Cold_Migration_Success_Count",
    "Cold_Migration_Failed_Count",
    "Cold_Migration_Latency",
    "Cold_Migration_Queue_Size",
    "ColdStorageSpaceUtilization"
  ],
  Search: [
    "ES_Search_Backpressure_Cancellation_Breakup",
    "ES_Search_Backpressure_Task_Cancelled_Percentage",
    "ES_Search_Backpressure_SearchTask_Metrics",
    "ES_Search_Backpressure_SearchShardTask_Metrics",
    "ES_Search_Backpressure_Mode",
    "ES_Concurrent_Search_Rate",
    "ES_Concurrent_Search_Latency",
    "ES_Concurrent_Search_Avg_Slice_Count",
    "ES_Asynchronous_search_request_rate",
    "ES_Asynchronous_search_failure_rate",
    "ES_Asynchronous_Search_rejected_state",
    "Request_Cache_Tier_Specific_Stats",
    "Request_Cache_Store_Type",
    "Request_Cache_Overall_Stats",
    "ES_Request_Rate",
    "ES_Search_queue_state",
    "ES_Latency",
    "Query_Success/Failure",
    "ES_Thread_Pool_Total_Wait_Time",
    "Scroll_Data"
  ],
  Indexing: [
    "ES_Indexing_Pressure_State",
    "ES_Indexing_Pressure_RejectionBreakup",
    "ES_Indexing_Pressure_RejectionBreakup_ShadowMode",
    "ES_Index_queue_state",
    "ES_Index_Searcher_Queue_State",
    "ES_Request_Rate",
    "ES_Bulk_queue_state"
  ],
  Requests: [
    "ES_Data_Plane_Total_Request_Count",
    "ES_Data_Plane_Invalid_HostHeader_Request_Count",
    "ES_Admission_Control_Rejection_Count",
    "ES_VPCProxy_TotalTime",
    "ES_VPCProxy_Sessions",
    "ES_VPCProxy_UnreachableELB",
    "ES_VPCProxy_ConnectionErrors"
  ]
};

export const PublicationMetricsWithSum = [
    "RemoteStateDiffDownloadFailedCount",
    "RemoteStateFullDownloadFailedCount",
    "RoutingTableRequestRetryCount",
    "RoutingTableFailure",
    "ClusterStateRequestRetryCount",
    "ClusterStateFailure"
];
