import {
  Button,
  Flashbar,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { a11yLight, CopyBlock } from 'react-code-blocks';
import { CommonDomainProps } from '../../../models/types';
import { CodeViewer } from './../../../Components/Common/CodeViewer';
import {
  BOOT_LOGS,
  ES_LOGS,
  INSTANCE_CONTROLLER_LOGS,
  KBN_LOGS,
  LogOption,
  LOG_PUSHER_LOGS,
  SDPD_AGENT_LOGS,
  SERVICE_PROXY_LOGS,
} from './constants';
import { useExecuteCommandMutation } from './hooks/useExecuteCommandMutation';
import { CISPE_COMPLIANCE_MESSAGE, CISPE_EMPTY_FIELD_WARNING, DISCLOSURE_MESSAGE } from '../../../constants'
import moment from 'moment';

const options = [
  ES_LOGS,
  KBN_LOGS,
  SERVICE_PROXY_LOGS,
  SDPD_AGENT_LOGS,
  LOG_PUSHER_LOGS,
  INSTANCE_CONTROLLER_LOGS,
  BOOT_LOGS,
];

interface LogViewerProps extends CommonDomainProps {
  initialLogFile: LogOption;
  instanceId: string;
  onClose: () => void;
}
const LogViewer = (props: LogViewerProps) => {
  const [getLogfile, { loading }] = useExecuteCommandMutation();
  // FIXME: Remove this and figure out stale logs. Pass the argument instead of reading it.
  const logLocations = useRef<{ [key: string]: any }>({});
  const [currentFileName, setCurrentFileName] = useState(props.initialLogFile);
  const [searchString, setSearchString] = useState('');
  const [ticket, setTicket] = useState('');
  const [description, setDescription] = useState('');
  const [currentLogs, setCurrentLogs] = useState('');
  const [emptyFieldMessage, setEmptyFieldMessage] = useState('');
  const [viewRawLog, setViewRawLog] = React.useState(false);

  const handleFetch = useCallback(async () => {
    try {
      if (viewRawLog && (!ticket || !description)) {
        setEmptyFieldMessage(CISPE_EMPTY_FIELD_WARNING)
      } else {
        setEmptyFieldMessage('');
        setCurrentLogs('');
        let fileName = "";
        /* 
        Accomodating varying log file name across different envs:
        - BootLogs : /apollo/var/log/a9-ec2-boot-start.log
        - ES 5.x and above : /apollo/env/<ES/OS env>/var/output/logs/elasticsearch.log
        - ES 1.5 and 2.3: /apollo/env/<ES/OS env>/var/output/logs/elasticsearch.log.YYYY-MM-DD-HH
        - opensearch : /var/opt/opensearch/var/logs/elasticsearch.log
        */
        if (currentFileName.env === 'Boot') {
          fileName = `/apollo/var/logs/${currentFileName.label}`;
        } else {
          let envName = logLocations.current[currentFileName.env];
          if (envName.indexOf("ES_2_3AMI") >=0 || envName.indexOf("ESAMI") >= 0) {
            let currentDateAndHour = moment().utc().format('YYYY-MM-DD-HH')
            fileName = `${envName}/var/output/logs/${currentFileName.label + "." + currentDateAndHour}`;
          } else if (envName.indexOf("opensearch") >= 0 || envName === '/var/opt/opensearch/var/logs') {
            fileName = `${envName}/${currentFileName.label}`;     
            console.log('Container path detected:', fileName);  
          } else {
            fileName = `${envName}/var/output/logs/${currentFileName.label}`;
          }
        }

        const isContainerPath = fileName.includes('/var/opt/opensearch/var/logs');
        let command, commandArgs;

        if (isContainerPath) {
          command = 'su';
          commandArgs = `- aosuser -c "tail -n 1000 ${fileName}"`;
          
          if (!isEmpty(searchString)) {
            const grepPatterns = searchString
              .split(',')
              .map((pattern: string) => `-e '${pattern}'`)
              .join(' ');
            commandArgs = `- aosuser -c "grep -m 500 -A 5 -B 5 -i ${grepPatterns} ${fileName}"`;
          }
        } else {
          command = 'tail';
          commandArgs = `-n 1000 ${fileName}`;
          
          if (!isEmpty(searchString)) {
            const grepPatterns = searchString
              .split(',')
              .map((pattern: string) => `-e '${pattern}'`)
              .join(' ');
            command = 'grep';
            commandArgs = `-m 500 -A 5 -B 5 -i ${grepPatterns} ${fileName}`;
          }
        }

        console.log('Executing command:', command, 'with args:', commandArgs);
        
        const resp = await getLogfile({
          variables: {
            url: 'aes/domain/dp/node/shell',
            args: {
              domain_identifier: props.domainIdentifier,
              base_cmd: command,
              args: commandArgs,
              truncate_output: false,
              instance_id: props.instanceId[0],
              ticket: ticket,
              description: description,
            },
          },
        });

        const executeCommand = get(resp, 'data.executeCommand', {});
        console.log('Execute command response:', executeCommand);

        if (!executeCommand.success) {
          console.error('Command failed:', executeCommand.error);
          setCurrentLogs(`Error reading logs: ${get(executeCommand, 'error.message', 'Unknown error')}`);
          return;
        }
        
        // Process successful response
        let data = executeCommand.data;
        if (!data) {
          console.log('No data in successful response');
          data = 'No logs found';
        } else if (typeof data !== 'string') {
          data = String(data);
        }
        
        // Ensure data ends with a newline
        if (!data.endsWith('\n')) {
          data = data + '\n';
        }
        
        console.log('Command succeeded, data length:', data.length);        
        setCurrentLogs(data);
      }
    } catch (e) {
      console.error('Error in handleFetch:', e);
      setCurrentLogs('Error fetching logs');
    }
  }, [props.instanceId, currentFileName, searchString, ticket, description, viewRawLog, emptyFieldMessage]);

  const handleEnvs = useCallback(async () => {
    try {
      const resp = await getLogfile({
        variables: {
          url: 'aes/domain/dp/node/shell',
          args: {
            domain_identifier: props.domainIdentifier,
            base_cmd: 'find',
            args: '/apollo/env -type l -not -xtype l',
            instance_id: props.instanceId[0],
          },
        },
      });

      // resp data varies for new ssm client. defining responsePath compatible with both new and existing ssm clients.
      const responsePath: string = (typeof resp?.data?.executeCommand?.data === 'string') ? 'data.executeCommand.data' : 'data.executeCommand.data[0].stdout';
      const envLocations: string[] = get(
        resp,
        responsePath,
        ''
      ).split('\n');
      logLocations.current = {
        /*
        ES/OS env patterns:
        - All OS versions - swift-<stack>-OS_<version>AMI-p00x
        - ES version 2.3 and above - swift-<stack>-ES_<version>AMI-p00x
        - ES 1.5 - swift-<stack>-ESAMI-p00x
        - opensearch - for container paths
        */
        ES:
          envLocations.find((logLoc: string) => logLoc.indexOf('ES_') > -1) ||
          envLocations.find((logLoc: string) => logLoc.indexOf('OS_') > -1) ||
          envLocations.find((logLoc: string) => logLoc.indexOf('ESAMI') > -1) ||
          '/var/opt/opensearch/var/logs',
        Kibana:
          envLocations.find(
            (logLoc: string) => logLoc.indexOf('Kibana') > -1
          ) ||
          envLocations.find((logLoc: string) => logLoc.indexOf('OSD') > -1),
        ServiceProxy: envLocations.find(
          (logLoc: string) => logLoc.indexOf('ServiceProxy') > -1
        ),
        SDPDAgent: envLocations.find(
          (logLoc: string) => logLoc.indexOf('SDPDAgent') > -1
        ),
        LogPusher: envLocations.find(
          (logLoc: string) => logLoc.indexOf('LogPusher') > -1
        ),
        InstanceController: envLocations.find(
          (logLoc: string) => logLoc.indexOf('InstanceController') > -1
        ),
      };
    } catch (e) {
      // console.log('error', e);
      // Handle error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.instanceId]);

  useEffect(() => {
    async function initialLoad() {
      await handleEnvs();
      await handleFetch();
    }
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        onDismiss={props.onClose}
        visible={true}
        closeAriaLabel="Close modal"
        size="max"
        header={
          <div>
            <Header variant="h2"> Log Explorer: <strong>{props.domainIdentifier}</strong> </Header>
            <Toggle
              onChange={({ detail }) => {
                setViewRawLog(detail.checked);
                if (!detail.checked) {
                  setTicket('');
                  setDescription('');
                } else {
                  setEmptyFieldMessage(CISPE_EMPTY_FIELD_WARNING)
                }
              }}
              checked={viewRawLog}
            >
              Raw logs mode
            </Toggle>
            <Flashbar items={[
              {
                type: "warning",
                content: DISCLOSURE_MESSAGE,
              }
            ]} />
            {viewRawLog ? (
              <Flashbar items={[
                {
                  type: "error",
                  content: CISPE_COMPLIANCE_MESSAGE,
                }
              ]} />
            ) : null}
          </div>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <Grid
            gridDefinition={[
              { colspan: { xxs: 3 } },
              { colspan: { xxs: 3 } },
              { colspan: { xxs: 2 } },
              { colspan: { xxs: 2 } },
              { colspan: { xxs: 2 } },
            ]}
          >
            <FormField
              constraintText="Choose logfile from list to analyze. Ensure this will show logs only from ENV/var/output/logs file."
              label={<strong>Log File</strong>}
            >
              <Select
                selectedOption={currentFileName}
                onChange={(selectedFile: any) => {
                  setCurrentFileName(selectedFile.detail.selectedOption);
                }}
                options={options}
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField
              constraintText="Provide string to search (e.g ERROR, EXCEPTION, WARN), it will perform grep command with given filter. This will additionally show 5 contextual lines."
              label={<strong>Search For</strong>}
            >
              <Input
                onChange={({ detail }) => setSearchString(detail.value)}
                value={searchString}
                placeholder="Search String"
              />
            </FormField>
            {viewRawLog ? (
              <FormField
                constraintText="Provide customer consent SIM/TT"
                errorText={emptyFieldMessage}
                label={<strong>Ticket</strong>}
              >
                <Input
                  onChange={({ detail }) => setTicket(detail.value)}
                  value={ticket}
                  placeholder="Ticket/Link/ID"
                />
              </FormField>
            ) : null}
            {viewRawLog ? (
              <FormField
                constraintText="Provide access justification description"
                errorText={emptyFieldMessage}
                label={<strong>Description</strong>}
              >
                <Input
                  onChange={async ({ detail }) => setDescription(detail.value)}
                  value={description}
                  placeholder="Description"
                />
              </FormField>
            ) : null}
            <div style={{ marginTop: "26px" }}>
              <Button variant="normal" onClick={handleFetch} loading={loading}>
                View logs
              </Button>
            </div>
          </Grid>
        </SpaceBetween>
        <CodeViewer
          codeData={currentLogs}
          language={{ label: 'neon', value: 'neon' }}
          theme={{ label: 'coy', value: 'coy' }}
        />
      </Modal>
    </div>
  );
};

export { LogViewer };
